import React, { useState } from 'react';
import { FaUsers, FaChevronDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Styles from '../../styles/components/WorkflowDetails/VersionedWorkflowCard.module.css';
import WorkflowVersionsModal from '../Modals/WorkflowVersionsModal';

function VersionedWorkflowCard({ workflows, onDelete }) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  // Add null checks and proper sorting
  const sortedWorkflows = [...workflows].sort((a, b) => {
    const versionA = a.version_number || 0;
    const versionB = b.version_number || 0;
    return versionA - versionB;
  });

  // Get base workflow (version 1 or first in list)
  const baseWorkflow = sortedWorkflows.find(w => w.version_number === 1) || sortedWorkflows[0];
  
  // Get latest version (marked as latest or last in list)
  const latestVersion = sortedWorkflows.find(w => w.is_latest) || sortedWorkflows[sortedWorkflows.length - 1];

  // Calculate total users with null check
  const totalUsers = workflows.reduce((sum, w) => sum + (w.user_count || 0), 0);

  // Get parent workflow (the one without parent_workflow)
  const parentWorkflow = workflows.find(w => !w.parent_workflow);
  
  // Check if a workflow can be deleted
  const canDeleteWorkflow = (workflow) => {
    // Cannot delete parent if it has children
    if (workflow.id === parentWorkflow?.id) {
      const hasChildren = workflows.some(w => w.parent_workflow === workflow.id);
      return !hasChildren;
    }
    return true; // Can delete any other version
  };

  if (!baseWorkflow || !latestVersion) {
    return null; // Or some error state UI
  }

  return (
    <>
      <div className={Styles.versionedCard} onClick={() => setShowModal(true)}>
        <div className={Styles.cardHeader}>
          <img
            src={latestVersion.image_url || `https://via.placeholder.com/300x200.png?text=${t('noImage')}`}
            alt={latestVersion.name}
            className={Styles.cardImage}
          />
          <div className={Styles.versionInfo}>
            <span className={Styles.baseVersion}>
              {baseWorkflow.created_at && `Created: ${new Date(baseWorkflow.created_at).toLocaleDateString()}`}
            </span>
            <span className={Styles.versionCount}>
              {workflows.length} {t('versions')}
            </span>
          </div>
        </div>

        <div className={Styles.cardBody}>
          <h2 className={Styles.cardTitle}>
            {latestVersion.name}
            <FaChevronDown className={Styles.expandIcon} />
          </h2>
          <span className={Styles.categoryPill}>
            {latestVersion.category || t('uncategorized')}
          </span>
          <p className={Styles.cardDescription}>
            {latestVersion.description || t('noDescription')}
          </p>
        </div>

        <div className={Styles.stats}>
          <span className={Styles.versionInfo}>
            v{latestVersion.version_number || '1'}
          </span>
          <span className={Styles.userCount}>
            <FaUsers /> {totalUsers}
          </span>
        </div>
      </div>

      <WorkflowVersionsModal
        show={showModal}
        onClose={() => setShowModal(false)}
        workflows={sortedWorkflows}
        onDelete={onDelete}
        canDeleteWorkflow={canDeleteWorkflow}
        parentWorkflowId={parentWorkflow?.id}
      />
    </>
  );
}

export default VersionedWorkflowCard;
