import React, { useState, useEffect } from 'react';
import { calculateLabelWidth } from './FieldTypesUtils';
import styles from '../../styles/components/FieldTypes/FieldTypes.module.css';

// List of configuration keys
export const TextFieldConfig = [
  "fieldLabel",
  "required",
  "help_text",
  "placeholder",
  "max_length",
  "default_value",
  "validation_type"
];

function TextField({ field, onConfigChange, isSelected }) {
  const [label, setLabel] = useState(field.config.fieldLabel);
  const [placeholder, setPlaceholder] = useState(field.config.placeholder);
  const [helpText, setHelpText] = useState(field.config.help_text || '');
  const isRequired = field.config.required;

  // Handle label change and pass it back to WorkflowBuilder
  const handleLabelChange = (e) => {
    const updatedConfig = { ...field.config, fieldLabel: e.target.value };
    onConfigChange(updatedConfig); // Notify WorkflowBuilder of the updated config
  };

  // Handle help text change and pass it back to WorkflowBuilder
  const handleHelpTextChange = (e) => {
    const updatedConfig = { ...field.config, help_text: e.target.value };
    setHelpText(e.target.value); // Update local state
    onConfigChange(updatedConfig); // Notify WorkflowBuilder of the updated config
  };

  useEffect(() => {
    setLabel(field.config.fieldLabel);
    setPlaceholder(field.config.placeholder);
    setHelpText(field.config.help_text || '');


    const labelWidth = calculateLabelWidth(field.config.fieldLabel);
    document.documentElement.style.setProperty('--label-length-'+field.id, labelWidth/8);


    

  }, [field.config]);
  

  return (
    <div className={styles.textField}>
      {/* Label Section */}
      <div className={styles.labelWrapper}>
        <div className={styles.labelContainer}>
          <textarea
            className={`${styles.fieldLabel} ${isRequired ? styles.required : ''}`}
            value={label}
            placeholder={isSelected ? "Type a label" : ""}
            onChange={handleLabelChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.currentTarget.blur(); // Stop editing on Enter
              }
            }}
            onInput={(e) => {
              e.target.style.height = 'auto';
              e.target.style.height = `${e.target.scrollHeight}px`;
            }}
            rows="1"
            wrap="soft"
          />
          {isRequired && <span 
            style= {{ left: 'max(min(calc(10px + 1ch *var(--label-length-'+field.id+')), calc(100% - 40px)),calc(14%)', top: '5px'
            , position: 'absolute', color: 'red'
            }}
            >
          *</span>}
        </div>
      </div>

      {/* Input Field Section */}
      <input
        className={styles.textfieldInput}
        type="text"
        placeholder={placeholder}
        maxLength={field.config.max_length || undefined} 
        defaultValue={field.config.default_value || ''}
        disabled
      />

      {/* Editable Help Text Section */}
      <textarea
        className={styles.helpText}
        value={helpText}
        placeholder={isSelected ? "Type a help text" : ""}
        onChange={handleHelpTextChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.currentTarget.blur(); // Stop editing on Enter
          }
        }}
        onInput={(e) => {
          e.target.style.height = 'auto';
          e.target.style.height = `${e.target.scrollHeight}px`;
        }}
        rows="1"
        wrap="soft"
      />
    </div>
  );
}

export default TextField;
