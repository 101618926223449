import React from 'react';
import { Link } from 'react-router-dom';
import { FaTrashAlt, FaUsers, FaCalendar, FaTimes, FaClock } from 'react-icons/fa';
import { MdModeEditOutline } from 'react-icons/md';
import styles from '../../styles/components/Modals/WorkflowVersionsModal.module.css';

function WorkflowVersionsModal({ 
  show, 
  onClose, 
  workflows, 
  onDelete,
  canDeleteWorkflow,
  parentWorkflowId
}) {
  if (!show) return null;

  const latestVersion = workflows.find(w => w.is_latest);

  // Helper function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric'
    });
  };

  return (
    <div className={styles.modalBackdrop} onClick={onClose}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <div className={styles.modalHeader}>
          <div className={styles.headerContent}>
            <h2>{latestVersion.name}</h2>
            <span className={styles.versionCount}>
              {workflows.length} Versions
            </span>
          </div>
          <button className={styles.closeButton} onClick={onClose}>
            <FaTimes />
          </button>
        </div>

        <div className={styles.modalBody}>
          <div className={styles.versionTimeline}>
            {workflows.map((workflow) => (
              <div 
                key={workflow.id} 
                className={`${styles.versionCard} 
                  ${workflow.is_latest ? styles.latestVersion : ''} 
                  ${workflow.id === parentWorkflowId ? styles.parentVersion : ''}`}
              >
                <div className={styles.versionHeader}>
                  <div className={styles.versionInfo}>
                    <span className={styles.versionNumber}>
                      v{workflow.version_number}
                      {workflow.id === parentWorkflowId && (
                        <span className={styles.parentBadge}>Parent</span>
                      )}
                    </span>
                    {workflow.is_latest && (
                      <span className={styles.latestBadge}>Latest</span>
                    )}
                    <span className={styles.dateInfo}>
                      <FaCalendar />
                      {formatDate(workflow.created_at)}
                    </span>
                  </div>
                  <div className={styles.versionStats}>
                    <span><FaUsers /> {workflow.user_count} Users</span>
                    <span><FaClock /> {workflow.submission_count || 0} Submissions</span>
                  </div>
                </div>

                <div className={styles.versionBody}>
                  <p>{workflow.description}</p>
                </div>

                <div className={styles.versionActions}>
                  <Link
                    to={`/admin-dashboard/edit-workflow/workflows/${workflow.id}`}
                    className={styles.actionButton}
                  >
                    <MdModeEditOutline /> Edit
                  </Link>
                  <Link
                    to={`/admin-dashboard/view/workflows/${workflow.id}`}
                    className={styles.actionButton}
                  >
                    <FaUsers /> View Submissions
                  </Link>
                  {canDeleteWorkflow(workflow) && (
                    <button
                      onClick={() => onDelete(workflow.id)}
                      className={styles.deleteButton}
                    >
                      <FaTrashAlt /> Delete
                    </button>
                  )}
                  
                  {!canDeleteWorkflow(workflow) && workflow.id === parentWorkflowId && (
                    <div className={styles.parentNote}>
                      Cannot delete parent version while other versions exist
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkflowVersionsModal;
