// AIAssistant.js

import React, { useState, useRef, useEffect } from 'react';
import { Send, X, Mic, MicOff } from 'lucide-react'; 
// ^ Mic and MicOff are icons from lucide-react; you could choose different icons
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import styles from '../../styles/components/WorkflowBuilder/AIAssistant.module.css';
import config from '../../config';

// Example avatar images:
import userAvatar from '../../assets/images/user-avatar.png';
import aiAvatar from '../../assets/images/ai-avatar2.png';

const AIAssistant = ({ showChat, toggleChat, onGenerateContent, instruction }) => {
  const [messages, setMessages] = useState([
    { role: 'assistant', content: 'Hello! How can I assist you with your workflow today?' },
    { role: 'assistant', content: 'Tell me about your workflow, and I\'ll turn it into a seamless process for you.'}
  ]);

  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);

  // Use Speech Recognition
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const textareaRef = useRef(null);
  const messagesEndRef = useRef(null);

  // Whenever transcript changes, update our input box
  useEffect(() => {
    setInput(transcript);
  }, [transcript]);

  const handleSend = async () => {
    if (input.trim()) {
      const userMessage = { role: 'user', content: input };
      setMessages(prevMessages => [...prevMessages, userMessage]);
      setInput('');
      resetTranscript();  // clear the speech transcript after sending
      setIsTyping(true);

      try {
        const response = await fetch(`${config.apiUrl}/ai-chat/`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ instruction, message: input }),
        });

        const data = await response.json();
        const parsedWorkflow = data.response;
        setIsTyping(false);

        if (parsedWorkflow.success === false) {
          // Failed to generate
          const aiMessage = { role: 'assistant', content: 'Failed to generate workflow. Please try again.' };
          const error = { role: 'assistant', content: parsedWorkflow.summary };
          setMessages(prevMessages => [...prevMessages, aiMessage, error]);
        } else {
          // Success
          onGenerateContent(parsedWorkflow);
          const aiMessage = { role: 'assistant', content: 'Workflow generated successfully!' };
          const workflowSummary = { role: 'assistant', content: parsedWorkflow.summary };
          setMessages(prevMessages => [...prevMessages, aiMessage, workflowSummary]);
        }
      } catch (error) {
        console.error('Error generating content:', error);
        const aiMessage = { role: 'assistant', content: 'Failed to generate workflow. Please try again.' };
        setMessages(prevMessages => [...prevMessages, aiMessage]);
        setIsTyping(false);
      }
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
    autoResizeTextarea();
  };

  const autoResizeTextarea = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  // Scroll to bottom when messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, isTyping]);

  // Start or stop listening based on the current state
  const toggleListening = () => {
    if (!browserSupportsSpeechRecognition) {
      alert("Speech Recognition is not supported in this browser.");
      return;
    }

    if (listening) {
      SpeechRecognition.stopListening();
    } else {
      // Clear the transcript before starting
      resetTranscript();
      // Start listening (continuous or not)
      SpeechRecognition.startListening({ continuous: true, language: 'en-US' });
    }
  };

  if (!showChat) return null;

  return (
    <div className={styles.container}>
      {/* Header */}
      <div className={styles.header}>
        <h3 className={styles.title}>AI Workflow Assistant</h3>
        <button onClick={toggleChat} className={styles.closeButton} aria-label="Close Chat">
          <X size={20} />
        </button>
      </div>

      {/* Messages */}
      <div className={styles.messages}>
        {messages.map((message, index) => (
          <div
            key={index}
            className={`${styles.messageContainer} ${
              message.role === 'user' ? styles.userMessageContainer : styles.assistantMessageContainer
            }`}
          >
            {/* Avatar on the assistant side */}
            {message.role === 'assistant' && (
              <img src={aiAvatar} alt="AI Avatar" className={styles.avatar} />
            )}

            {/* Message Bubble */}
            <div className={message.role === 'user' ? styles.userMessage : styles.assistantMessage}>
              {message.content}
            </div>

            {/* Avatar on the user side */}
            {message.role === 'user' && (
              <img src={userAvatar} alt="User Avatar" className={styles.avatar} />
            )}
          </div>
        ))}

        {/* Typing Indicator */}
        {isTyping && (
          <div className={`${styles.messageContainer} ${styles.assistantMessageContainer}`}>
            <img src={aiAvatar} alt="AI Avatar" className={styles.avatar} />
            <div className={styles.typingIndicator}>
              <span className={styles.dot}></span>
              <span className={styles.dot}></span>
              <span className={styles.dot}></span>
            </div>
          </div>
        )}

        <div ref={messagesEndRef} />
      </div>

      {/* Input and Microphone Section */}
      <div className={styles.inputArea}>
        <button
          onClick={toggleListening}
          className={`${styles.micButton} ${listening ? styles.listening : ''}`}
          aria-label="Toggle Voice Input"
        >
          {listening ? <MicOff size={20} /> : <Mic size={20} />}
        </button>

        <textarea
          ref={textareaRef}
          placeholder="Describe your workflow..."
          value={input}
          onChange={handleInputChange}
          className={styles.inputField}
          rows="1"
        />
        
        <button
          onClick={handleSend}
          disabled={!input.trim()}
          className={styles.sendButton}
          aria-label="Send Message"
        >
          <Send size={20} />
        </button>
      </div>
    </div>
  );
};

export default AIAssistant;
