import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FieldConfigWindow from './FieldConfigWindow'; // Window for field configuration
import styles from '../../styles/components/WorkflowBuilder/WorkflowBuilder.module.css';
import ReactQuill from 'react-quill'; // Rich text editor component
import 'react-quill/dist/quill.snow.css'; // Import the editor styles
import { v4 as uuidv4 } from 'uuid';
import Toolbar, { toolbarTabs } from './Toolbar'; // Adjust import to include toolbarTabs
import { fieldConfigs, fieldTypeComponents } from '../FieldTypes/FieldDictionaries';

function WorkflowBuilder({
  tasks, // Array of tasks
  setTasks, // Function to update tasks
  workflowName, // Workflow name
  setWorkflowName, // Function to update workflow name
  showOriginalNavbar
}) {
  const [selectedFieldId, setSelectedFieldId] = useState(null);
  const [selectedField, setSelectedField] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [showAddTask, setShowAddTask] = useState(false);
  const [isToolbarOpen, setIsToolbarOpen] = useState(false);

  const maxDescriptionLength = 100;

  // Toggle toolbar visibility
  const toggleToolbar = () => {
    setIsToolbarOpen(!isToolbarOpen);
  };

  useEffect(() => {
    if (selectedFieldId) {
      const selectedTask = tasks.find((task) => 
        Array.isArray(task.fields) && task.fields.find((field) => field.id === selectedFieldId)
      );
      const selectedTaskField = selectedTask?.fields.find(
        (field) => field.id === selectedFieldId
      );
      const fieldIndex = selectedTask?.fields.findIndex(
        (field) => field.id === selectedFieldId
      );
      if (selectedTaskField && fieldIndex !== -1) {
        setSelectedField({ taskId: selectedTask.id, field: selectedTaskField, fieldIndex});
      }
    }
    console.log(tasks);
  }, [selectedFieldId, tasks]);

  // Open the config window only when clicking "Configure" but update it if the modal is already open
  useEffect(() => {
    if (selectedField && modalOpen) {
      // If the modal is open and a new field is selected, update the window
      setModalOpen(true);
    }
  }, [selectedField], modalOpen);


  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    const { source, destination, draggableId, type } = result;
  
    // Return if there is no destination (e.g., dropped outside)
    if (!destination) return;
  
    // Reorder tasks if the dragged type is a task
    if (type === 'task') {
      const reorderedTasks = reorder(tasks, source.index, destination.index);
      setTasks(reorderedTasks);
      return;
    }
  
    // Dragging from the toolbar to a specific task
    if (source.droppableId === 'toolbar' && destination.droppableId.startsWith('task-')) {
      const taskIndex = tasks.findIndex((task) => `task-${task.id}` === destination.droppableId);
      if (taskIndex === -1) return;
  
      // Find the dragged field in `initialFields`
      const draggedField = Object.values(toolbarTabs).flat().find(field => `toolbar-${field.id}` === draggableId);
      if (draggedField) {
        const initialConfig = (fieldConfigs[draggedField.type] || ["fieldLabel"]).reduce((acc, key) => {
          acc[key] = "";
          return acc;
        }, {});
        initialConfig.fieldLabel = { ...draggedField }.label + " Field";
        initialConfig.required = true;
        
        if (draggedField.type === 'multi_select_checkboxes') {
          initialConfig.choices = "Choice 1\nChoice 2\nChoice 3";
        }
        if (draggedField.type === 'radiobutton') {
          initialConfig.choices = "Option 1\nOption 2\nOption 3";
        }

        console.log(initialConfig);

        // Add a new field instance to the selected task
        setTasks((prevTasks) => {
          const updatedTasks = [...prevTasks];
          const newField = {
            ...draggedField,
            id: uuidv4(), // Generate unique ID for the new field instance
            config: initialConfig,
          };
          setSelectedFieldId(newField.id); // Select the newly added field

          updatedTasks[taskIndex].fields.splice(destination.index, 0, newField);
          return updatedTasks;
        });
      }
      return;
    }
  
    // Reordering fields within or between tasks
    if (type === 'field') {
      const sourceTaskIndex = tasks.findIndex((task) => `task-${task.id}` === source.droppableId);
      const destinationTaskIndex = tasks.findIndex((task) => `task-${task.id}` === destination.droppableId);
  
      if (sourceTaskIndex === -1 || destinationTaskIndex === -1) return;
  
      const sourceTask = tasks[sourceTaskIndex];
      const destinationTask = tasks[destinationTaskIndex];
  
      // Remove the field from the source and insert it into the destination
      const [movedField] = sourceTask.fields.splice(source.index, 1);
      destinationTask.fields.splice(destination.index, 0, movedField);
  
      setTasks([...tasks]);
    }
  };
  
  

  const handleFieldConfigChange = (taskId, fieldIndex, updatedConfig) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) => {
        if (task.id === taskId) {
          const updatedFields = task.fields.map((field, index) =>
            index === fieldIndex
              ? { ...field, config: updatedConfig }
              : field
          );
          return { ...task, fields: updatedFields };
        }
        return task;
      })
    );
  };
  

  // Delete Task
  const deleteTask = (taskId) => {
    setTasks(tasks.filter((task) => task.id !== taskId));
  };

  const handleTaskChange = (taskId, key, value) => {
    setTasks((prevTasks) => {
      const updatedTasks = [...prevTasks];
      const task = updatedTasks.find((task) => task.id === taskId);
      task[key] = value;
      return updatedTasks;
    });
  };

  const openConfigWindow = (taskId, field, fieldIndex) => {
    
    // Pass the field object with its config
    setSelectedField({
      taskId,
      field: {
        ...field,
        config: field.config || { fieldLabel: "" }  // Initialize with fieldLabel if config is undefined
      },
      fieldIndex,
    });
    setModalOpen(true);
  };

  const closeConfigWindow = () => {
    setModalOpen(false);
    setSelectedField(null);
  };

  const deleteField = (taskId, fieldIndex) => {
    setTasks((prevTasks) => {
      // Create a deep copy to avoid shallow update issues
      const updatedTasks = prevTasks.map((task) => ({
        ...task,
        fields: task.fields ? [...task.fields] : [],
      }));
      
      const task = updatedTasks.find((task) => task.id === taskId);
      if (task) {
        task.fields = task.fields.filter((_, index) => index !== fieldIndex);
      }
      return updatedTasks;
    });
  };

  const addTask = () => {
    const newTask = {
      id: uuidv4(),
      name: `Task ${tasks.length + 1}`,
      description: '',
      fields: [],
    };
    setTasks([...tasks, newTask]);
  };

  const toggleTaskCollapse = (taskId) => {
    setTasks(prevTasks =>
      prevTasks.map(task =>
        task.id === taskId ? { ...task, collapsed: !task.collapsed } : task
      )
    );
  };

  const addTaskAtPosition = (position) => {
    const newTask = {
      id: uuidv4(),
      name: `Task ${tasks.length + 1}`,
      description: '',
      fields: [],
    };
    const updatedTasks = [...tasks];
    updatedTasks.splice(position, 0, newTask); // Insert task at the specified position
    setTasks(updatedTasks);
  };
  

  const handleBackgroundClick = () => {
    setSelectedFieldId(null);
    setModalOpen(false);
  };

  // Add this helper function
  const normalizeFieldType = (type) => {
    return typeof type === 'string' ? type.toLowerCase() : type;
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={styles.workflowBuilder} onClick={handleBackgroundClick}>
        {/* Toolbar Section */}
        <Toolbar showOriginalNavbar = {showOriginalNavbar} />

        {/* Task Management Section */}
        <Droppable droppableId="all-tasks" type="task">
          {(provided) => (
            <div className={styles.tasksSection} ref={provided.innerRef} {...provided.droppableProps}>
              {/* Workflow Name Input */}
              <div className={styles.workflowHeader}>
                <input
                  type="text"
                  className={styles.workflowInput}
                  value={workflowName}
                  onChange={(e) => setWorkflowName(e.target.value)}
                  placeholder="Enter workflow name..."
                />
                <button onClick={addTask} className={styles.addTaskButtonOriginal}>
                  <i className="fa fa-plus"></i>
                  Add New Task
                </button>
              </div>

              {tasks && tasks.map((task, index) => (
                <React.Fragment key={task.id}>
                  <Draggable draggableId={task.id} index={index} key={task.id}>
                    {(provided) => (
                      <div
                        className={`${styles.task} ${task.collapsed ? styles.collapsed : ''}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className={styles.taskHeader}>
                          <div className={styles.taskHeaderLeft}>
                            <div className={styles.taskDragHandle}>
                              <span><i></i><i></i></span>
                              <span><i></i><i></i></span>
                              <span><i></i><i></i></span>
                            </div>
                            <div className={styles.taskTitleWrapper}>
                              <input
                                className={styles.taskNameField}
                                type="text"
                                value={task.name}
                                onChange={(e) => handleTaskChange(task.id, 'name', e.target.value)}
                                placeholder="Task Name"
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                    e.currentTarget.blur();
                                  }
                                }}
                              />
                              <textarea
                                className={styles.taskDescriptionField}
                                value={task.description}
                                onChange={(e) => handleTaskChange(task.id, 'description', e.target.value)}
                                placeholder="Task Description"
                              />
                            </div>
                          </div>
                          
                          <div className={styles.taskHeaderRight}>
                            <button 
                              onClick={() => deleteTask(task.id)} 
                              className={styles.deleteTaskButton}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                            <button 
                              onClick={() => toggleTaskCollapse(task.id)} 
                              className={styles.collapseButton}
                            >
                              {task.collapsed ? <i className="fa fa-chevron-down"></i> : <i className="fa fa-chevron-up"></i>}
                            </button>
                          </div>
                        </div>
                        
                        {/* Task Fields */}
                        <Droppable droppableId={`task-${task.id}`} type="field">
                          {(provided, snapshot) => (
                            <div className={` ${snapshot.isDraggingOver ? styles.dragOverWhole : ''} ${
                                task.collapsed ? styles.collapsedTaskFields : styles.taskFields
                              }`}
                              ref={provided.innerRef} {...provided.droppableProps}
                              onClick={() => {
                                if (!isToolbarOpen) toggleToolbar(); // Open toolbar on click
                              }}>

                              {!task.collapsed && task.fields.length === 0 && (
                                <div className={` ${snapshot.isDraggingOver ? styles.dragOver : ''} ${
                                  styles.dragFieldPlaceHolder}`}>
                                    Drag desired field types here
                                </div>
                              )}
                              {!task.collapsed && task.fields && task.fields.map((field, fieldIndex) => (
                                <Draggable
                                  key={field.id}
                                  draggableId={field.id}
                                  index={fieldIndex}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className={`${styles.taskField} ${
                                        field.id === selectedFieldId ? styles.selected : ''
                                      } ${snapshot.isDragging ? styles.dragging : ''}`}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      onClick={(e) => { e.stopPropagation(); setSelectedFieldId(field.id); }}
                                    >
                                      <div className={styles.dragHandle}>⋮⋮</div>
                                      {
                                        normalizeFieldType(field.type) in fieldTypeComponents && React.createElement(
                                          fieldTypeComponents[normalizeFieldType(field.type)],
                                          {
                                            field,
                                            onConfigChange: (updatedConfig) => handleFieldConfigChange(task.id, fieldIndex, updatedConfig),
                                            isSelected: field.id === selectedFieldId
                                          }
                                        )
                                      }

                                      {/* Configure and Delete buttons */}
                                      {field.id === selectedFieldId && (
                                        <div className={styles.fieldOptions}>
                                          <button
                                            onClick={() => openConfigWindow(task.id, field, fieldIndex)}
                                            className={styles.editFieldButton}
                                          >
                                            <i className="fa fa-cog"></i>
                                          </button>
                                          <button
                                            className={styles.deleteFieldButton}
                                            onClick={() => deleteField(task.id, fieldIndex)}
                                          >
                                            <i className="fa fa-trash"></i>
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    )}
                  </Draggable>

                  {/* Render gap between tasks for the button */}
                  {index < tasks.length && (
                    <div
                      className={styles.addTaskBetween}
                      onMouseEnter={() => setShowAddTask(true)}
                      onMouseLeave={() => setShowAddTask(false)}
                    >
                      {showAddTask && (
                        <button className={styles.addTaskButton} onClick={() => addTaskAtPosition(index + 1)}>
                          + Add New Task
                        </button>
                      )}
                    </div>
                  )}
                </React.Fragment>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>

      {modalOpen && selectedField && (
        <FieldConfigWindow
          field={selectedField.field}
          onClose={closeConfigWindow}
          onConfigChange={(updatedConfig) =>
            handleFieldConfigChange(selectedField.taskId, selectedField.fieldIndex, updatedConfig)
          }
          showOriginalNavbar={showOriginalNavbar}
        />
      )}
    </DragDropContext>
  );

}

export default WorkflowBuilder;
