import { descriptions, labels, types, fieldTypes } from '../FieldTypes/FieldDictionaries';
import styles from '../../styles/components/WorkflowBuilder/FieldConfigWindow.module.css';
import { useState, useEffect, useRef } from 'react';

function FieldConfigWindow({ field, onClose, onConfigChange, showOriginalNavbar }) {
  const [activeTab, setActiveTab] = useState('general');
  const [fieldConfig, setFieldConfig] = useState(field.config || {});
  const [multiCheckboxOpen, setMultiCheckboxOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // Controls the slide-in/out state

  const multiCheckboxRef = useRef(null);

  const handleFieldConfigChange = (key, value) => {
    const updatedConfig = { ...fieldConfig, [key]: value };
    setFieldConfig(updatedConfig);
    onConfigChange(updatedConfig);
  };

  // Trigger the slide-in animation once mounted
  useEffect(() => {
    setFieldConfig(field.config || {});
    setTimeout(() => setIsOpen(true), 10);
  }, [field.config]);

  useEffect(() => {
    const configWindowTop = showOriginalNavbar ? '102px' : '42px';
    document.documentElement.style.setProperty('--config-window-top', configWindowTop);
  }, [showOriginalNavbar]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (multiCheckboxRef.current && !multiCheckboxRef.current.contains(event.target)) {
        setMultiCheckboxOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Enforce max_selections constraint if changed after defaults selected
  useEffect(() => {
    const maxSelections = fieldConfig.max_selections || Infinity;
    const defaults = fieldConfig.default_values_checkboxes || [];

    if (defaults.length > maxSelections) {
      const truncatedDefaults = defaults.slice(0, maxSelections);
      handleFieldConfigChange('default_values_checkboxes', truncatedDefaults);
    }
  }, [fieldConfig.max_selections, fieldConfig.default_values_checkboxes]);

  // Remove default values that no longer exist in edited choices
  useEffect(() => {
    const currentChoices = (fieldConfig.choices || '').split('\n').filter((c) => c.trim() !== '');
    const defaults = fieldConfig.default_values_checkboxes || [];
    const filteredDefaults = defaults.filter((val) => currentChoices.includes(val));
    if (filteredDefaults.length !== defaults.length) {
      handleFieldConfigChange('default_values_checkboxes', filteredDefaults);
    }
  }, [fieldConfig.choices]);

  const renderMultiCheckbox = (key) => {
    const choices = fieldConfig.choices ? fieldConfig.choices.split('\n').filter((c) => c.trim() !== '') : [];
    const defaultValues = fieldConfig[key] || [];
    const minSelections = fieldConfig.min_selections || 0;
    const maxSelections = fieldConfig.max_selections || choices.length;

    const handleCheckboxChange = (value) => {
      const updatedValues = defaultValues.includes(value)
        ? defaultValues.filter((v) => v !== value)
        : [...defaultValues, value];

      if (updatedValues.length > maxSelections) return;

      handleFieldConfigChange(key, updatedValues);
    };

    const selectedText = defaultValues.length > 0
      ? defaultValues.join(', ')
      : 'Select options...';

    return (
      <div className={styles.multiCheckboxContainer} ref={multiCheckboxRef}>
        <div
          className={styles.multiCheckboxSpinner}
          onClick={() => setMultiCheckboxOpen(!multiCheckboxOpen)}
        >
          {selectedText}
          <i className={`fa fa-caret-${multiCheckboxOpen ? 'up' : 'down'}`} style={{ marginLeft: '5px' }}></i>
        </div>
        {multiCheckboxOpen && (
          <div className={styles.multiCheckboxDropdown}>
            {choices.map((choice, index) => (
              <label key={index} className={styles.multiCheckboxItem}>
                <input
                  type="checkbox"
                  className={styles.standardCheckbox}
                  value={choice}
                  checked={defaultValues.includes(choice)}
                  onChange={() => handleCheckboxChange(choice)}
                />
                {choice}
              </label>
            ))}
            <p className={styles.checkboxNote}>
              Select between {minSelections} and {maxSelections} options.
            </p>
          </div>
        )}
      </div>
    );
  };

  // Handle close with animation
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleTransitionEnd = () => {
    // Once the slide-out transition completes (isOpen = false),
    // we call the parent's onClose to remove the component
    if (!isOpen) {
      onClose();
    }
  };

  return (
    <div
      className={`${styles.configWindow} ${isOpen ? styles.configWindowOpen : ''}`}
      onTransitionEnd={handleTransitionEnd}
    >
      <div className={styles.header}>
        <h3>{field.type} Properties</h3>
        <button className={styles.closeButton} onClick={handleClose}>
          <i className="fa fa-times"></i>
        </button>
      </div>

      <div className={styles.tabContainer}>
        <button
          className={activeTab === 'general' ? styles.activeTab : ''}
          onClick={() => setActiveTab('general')}
        >
          General
        </button>
        <button
          className={activeTab === 'advanced' ? styles.activeTab : ''}
          onClick={() => setActiveTab('advanced')}
        >
          Advanced
        </button>
      </div>

      <div className={styles.tabContent}>
        {Object.keys(fieldConfig).filter((key) => (types[key] || 'general') === activeTab).map((key) => (
          <div key={key} className={styles.formGroup}>
            <label>{labels[key] || key}</label>
            {key === 'default_value_spinner' ? (
              <div className={styles.spinnerContainer}>
                <select
                  className={styles.customSpinner}
                  value={fieldConfig[key] || ''}
                  onChange={(e) => handleFieldConfigChange(key, e.target.value)}
                >
                  <option value="">
                    Select default value
                  </option>
                  {(fieldConfig.choices || '').split('\n').map((choice, index) => (
                    <option key={index} value={choice}>
                      {choice}
                    </option>
                  ))}
                </select>
              </div>
            ) : key === 'default_values_checkboxes' ? (
              renderMultiCheckbox(key)
            ) : key === 'validation_type' ? (
              <div className={styles.spinnerContainer}>
                <select
                  className={styles.customSpinner}
                  value={fieldConfig[key] || 'none'}
                  onChange={(e) => handleFieldConfigChange(key, e.target.value)}
                >
                  <option value="none">None</option>
                  <option value="email">Email</option>
                  <option value="alphabetic">Alphabetic</option>
                  <option value="alphanumeric">Alphanumeric</option>
                  <option value="url">URL</option>
                </select>
              </div>
            ) : fieldTypes[key] === 'textarea' ? (
              <textarea
                className={styles.fieldTextArea}
                value={fieldConfig[key] || ""}
                onChange={(e) => handleFieldConfigChange(key, e.target.value)}
                placeholder="Enter each choice on a new line"
                rows="4"
              />
            ) : (
              <input
                type={fieldTypes[key] || 'text'}
                value={fieldTypes[key] === 'checkbox' ? undefined : fieldConfig[key] || ""}
                checked={fieldTypes[key] === 'checkbox' ? fieldConfig[key] || false : undefined}
                onChange={(e) =>
                  handleFieldConfigChange(
                    key,
                    e.target.type === 'checkbox' ? e.target.checked : e.target.value
                  )
                }
                className={fieldTypes[key] === 'checkbox' ? styles.toggleCheckbox : ''}
              />
            )}
            <p className={styles.description}>{descriptions[key] || "Configure this field"}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FieldConfigWindow;
