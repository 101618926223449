import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../login/UserContext';
import styles from '../../styles/pages/Home/home.module.css';
import logo from '../../assets/images/workflow_logo.png';
import workflowDragAndDrop from '../../assets/images/Workflow_DragAndDrop.gif';
import workflowChatbot from '../../assets/images/Workflow_ChatBot.gif';
import workflowFromPDF from '../../assets/images/Workflow_From_PDF.gif';
import workflowFromTemplates from '../../assets/images/Workflow_From_Template.gif';
import workflowUpdatePRofile from '../../assets/images/Update_Profile.gif';
import i18n from '../../i18n';

function HomePage() {

  const { user } = useContext(UserContext); // Add this line
  const { t } = useTranslation();
  const [numCreated, setNumCreated] = useState(120);    // e.g. total workflows in DB
  const [numAdded, setNumAdded] = useState(300);        // workflows users have instantiated
  const [numSubmitted, setNumSubmitted] = useState(200); // workflows fully completed
  const [numUsers, setNumUsers] = useState(450);        // total registered users
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // NAVBAR SCROLL STATE
  const [navScrolled, setNavScrolled] = useState(false);

  // TYPING ANIMATION
  const typedPhrases = t('home.typedPhrases', { returnObjects: true }) || [];
  const [typedText, setTypedText] = useState('');
  const [typingIndex, setTypingIndex] = useState(0);
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [deleting, setDeleting] = useState(false);

  const TYPING_SPEED = 60;
  const DELETING_SPEED = 30;
  const PAUSE_DURATION = 1500;


  // Add this useEffect to check authentication status
  useEffect(() => {
    const checkAuth = () => {
      if (user && user.authToken) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, [user]); // This will run whenever user changes




  useEffect(() => {
    const handleScroll = () => {
      setNavScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (!typedPhrases.length) return; // guard if translations not loaded

    const currentStr = typedPhrases[currentPhraseIndex];
    let timer;

    if (!deleting && typingIndex < currentStr.length) {
      // Type next char
      timer = setTimeout(() => {
        setTypedText(prev => prev + currentStr[typingIndex]);
        setTypingIndex(typingIndex + 1);
      }, TYPING_SPEED);
    } else if (!deleting && typingIndex === currentStr.length) {
      // pause, then delete
      timer = setTimeout(() => setDeleting(true), PAUSE_DURATION);
    } else if (deleting && typingIndex > 0) {
      // delete characters
      timer = setTimeout(() => {
        setTypedText(currentStr.slice(0, typingIndex - 1));
        setTypingIndex(typingIndex - 1);
      }, DELETING_SPEED);
    } else if (deleting && typingIndex === 0) {
      // move to next phrase
      setDeleting(false);
      setCurrentPhraseIndex((prev) => (prev + 1) % typedPhrases.length);
    }

    return () => clearTimeout(timer);
  }, [typedText, typingIndex, deleting, currentPhraseIndex, typedPhrases]);

  // SCROLL ANIMATIONS for instructions
  const instructionItemsRef = useRef([]);
  instructionItemsRef.current = [];

  useEffect(() => {
    const observerOptions = { root: null, threshold: 0.15 };
    const observerCallback = (entries) => {
      entries.forEach(entry => {
        const anim = entry.target.dataset.animation;
        if (entry.isIntersecting) {
          if (anim === 'left') {
            entry.target.classList.add(styles.fadeInLeft);
            entry.target.classList.remove(styles.fadeOutLeft);
          } else {
            entry.target.classList.add(styles.fadeInRight);
            entry.target.classList.remove(styles.fadeOutRight);
          }
        } else {
          // re-hide
          if (anim === 'left') {
            entry.target.classList.remove(styles.fadeInLeft);
            entry.target.classList.add(styles.fadeOutLeft);
          } else {
            entry.target.classList.remove(styles.fadeInRight);
            entry.target.classList.add(styles.fadeOutRight);
          }
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    instructionItemsRef.current.forEach(el => {
      if (el) observer.observe(el);
    });
    return () => observer.disconnect();
  }, []);

  const addToRefs = (el) => {
    if (el && !instructionItemsRef.current.includes(el)) {
      instructionItemsRef.current.push(el);
    }
  };

  // Smooth scroll
  const scrollToSection = (id) => {
    const el = document.getElementById(id);
    if (el) el.scrollIntoView({ behavior: 'smooth' });
  };

  const handleLanguageChange = (e) => {
    const newLang = e.target.value;
    i18n.changeLanguage(newLang);        // Switch react-i18next language
    localStorage.setItem('appLang', newLang); // Persist in localStorage
  };

  const renderAuthButtons = () => {
    if (user && user.authToken) {
      return (
        <button
          className={`${styles.btn} ${styles.btnPrimary} ${styles.btnAnimated}`}
          onClick={() => window.location.href = '/user-dashboard'}
        >
          {t('nav.dashboard')}
        </button>
      );
    }
    return (
      <>
        <button
          className={`${styles.btn} ${styles.btnAnimated}`}
          onClick={() => window.location.href = '/login'}
        >
          {t('nav.login')}
        </button>
        <button
          className={`${styles.btn} ${styles.btnPrimary} ${styles.btnAnimated}`}
          onClick={() => window.location.href = '/register'}
        >
          {t('nav.getStarted')}
        </button>
      </>
    );
  };

  return (
    <div className={styles.homeContainer}>
      {/* NAVBAR */}
      <nav className={`${styles.navbar} ${navScrolled ? styles.navbarScrolled : ''}`}>
        <div className={styles.navbarLeft}>
          <img
            src={logo}
            alt="Workflow Logo"
            className={styles.navbarLogo}
          />
          <span className={styles.navbarTitle}>{t('nav.title')}</span>
          <a className={styles.navLink} onClick={() => scrollToSection('hero')}>
            {t('nav.overview')}
          </a>
          <a className={styles.navLink} onClick={() => scrollToSection('features')}>
            {t('nav.features')}
          </a>
        </div>
        <div className={styles.navbarRight}>
          <select className={styles.languageSelector}
            value={i18n.language}    // Bind to current language
            onChange={handleLanguageChange}
          >
            <option value="en">English</option>
            <option value="ar">العربية</option>
          </select>
          {renderAuthButtons()}

        </div>
      </nav>

      {/* HERO SECTION */}
      <section id="hero" className={styles.heroSection}>
        <div className={styles.blobOne}></div>
        <div className={styles.blobTwo}></div>
        <div className={styles.heroOverlay}></div>
        <div className={styles.heroContent}>
          <h1 className={styles.heroTitle}>
            {typedText}
            <span className={styles.cursor}>|</span>
          </h1>
          <p className={styles.heroText}>{t('home.heroText')}</p>
          <button
            className={`${styles.btn} ${styles.btnPrimary} ${styles.btnAnimated}`}
            onClick={() => (window.location.href = '/browse-workflows')}
          >
            {t('home.browseWorkflows')}
          </button>
        </div>
      </section>

      {/* INSTRUCTIONS */}
      <section id="features" className={styles.instructionsSection}>
        <div ref={addToRefs} className={styles.instructionItem} data-animation="left">
          <div className={styles.instructionContent}>
            <h2 className={styles.instructionTitle}>
              {t('home.buildWorkflowTitle')}
            </h2>
            <p className={styles.instructionDesc}>
              {t('home.buildWorkflowDesc')}
            </p>
          </div>
          <div className={styles.instructionImage}>
            <img
              src={workflowDragAndDrop}
              alt="Drag and Drop Workflow"
            />
          </div>
        </div>

        <div
          ref={addToRefs}
          className={`${styles.instructionItem} ${styles.reverseLayout}`}
          data-animation="right"
        >
          <div className={styles.instructionContent}>
            <h2 className={styles.instructionTitle}>
              {t('home.sendPolishedTitle')}
            </h2>
            <p className={styles.instructionDesc}>
              {t('home.sendPolishedDesc')}
            </p>
          </div>
          <div className={styles.instructionImage}>
            <img
              src={workflowChatbot}
              alt="Chatbot Workflow"
            />
          </div>
        </div>

        <div ref={addToRefs} className={styles.instructionItem} data-animation="left">
          <div className={styles.instructionContent}>
            <h2 className={styles.instructionTitle}>
              {t('home.analyzeTitle')}
            </h2>
            <p className={styles.instructionDesc}>
              {t('home.analyzeDesc')}
            </p>
          </div>
          <div className={styles.instructionImage}>
            <img
              src={workflowFromPDF}
              alt="PDF Workflow"
            />
          </div>
        </div>

        <div
          ref={addToRefs}
          className={`${styles.instructionItem} ${styles.reverseLayout}`}
          data-animation="right"
        >
          <div className={styles.instructionContent}>
            <h2 className={styles.instructionTitle}>
              {t('home.accessAnytimeTitle')}
            </h2>
            <p className={styles.instructionDesc}>
              {t('home.accessAnytimeDesc')}
            </p>
          </div>
          <div className={styles.instructionImage}>
            <img
              src={workflowFromTemplates}
              alt="Template Workflow"
            />
          </div>
        </div>
      </section>

      {/* CTA SECTION */}
      <section className={styles.ctaSection}>
        <div className={styles.ctaContent}>
          <h2 className={styles.ctaTitle}>{t('home.ctaTitle')}</h2>
          <p className={styles.ctaText}>{t('home.ctaDesc')}</p>
          <button
            className={`${styles.btn} ${styles.btnLight} ${styles.btnAnimated}`}
            onClick={() => window.location.href = (user && user.authToken) ? '/user-dashboard' : '/register'}
          >
            {(user && user.authToken) ? t('nav.dashboard') : t('nav.getStarted')}

          </button>
        </div>
      </section>

      {/* FOOTER */}
      <footer className={styles.footerSection}>
        <div className={styles.footerContent}>
          <p className={styles.footerText}>
            &copy; 2025 {t('nav.title')}. All Rights Reserved.
          </p>
          <ul className={styles.footerLinks}>
            <li><a href="#0">Privacy Policy</a></li>
            <li><a href="#0">Terms of Service</a></li>
            <li><a href="#0">Contact Us</a></li>
          </ul>
        </div>
      </footer>
    </div>
  );
}

export default HomePage;

