import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const validateToken = async (token) => {
        try {
            const response = await axios.post(`${config.apiUrl}/auth/validate-token/`, {
                token: token
            });
            return response.data.valid;
        } catch (error) {
            console.error('Token validation error:', error);
            return false;
        }
    };

    const checkAndUpdateToken = async () => {
        const authToken = localStorage.getItem('authToken');
        if (authToken) {
            const isValid = await validateToken(authToken);
            if (isValid) {
                setUser({ authToken });
            } else {
                logout();
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        checkAndUpdateToken();
    }, []);

    const logout = () => {
        localStorage.removeItem('authToken');
        setUser(null);

        // validate token
        validateToken();


    };

    // Periodic token validation (optional)
    useEffect(() => {
        if (user) {
            const interval = setInterval(() => {
                checkAndUpdateToken();
            }, 5 * 60 * 1000); // Check every 5 minutes
            return () => clearInterval(interval);
        }
    }, [user]);

    return (
        <UserContext.Provider value={{ user, setUser, logout, loading }}>
            {children}
        </UserContext.Provider>
    );
};
