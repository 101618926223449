import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { FormItem } from "./FormItem";
import styles from '../../styles/components/WorkflowDetails/MultiStepForm.module.css';

export const MultiStepForm = forwardRef(({ task, step, onPageUpdate, workflowId, userTask }, ref) => {
  const [answers, setAnswers] = useState({});
  const [fieldsType, setFieldsType] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    const initialAnswers = {};
    userTask.user_task_fields.forEach(item => {
      initialAnswers[item.fieldName] = item.fieldValue || '';
    });
    setAnswers(initialAnswers);
  }, [step, userTask]);

  const updateAnswers = (value, fieldName) => {
    const updatedAnswers = { ...answers, [fieldName]: value };
    setAnswers(updatedAnswers);
    try {
      onPageUpdate(step, updatedAnswers);
    } catch (error) {
      console.error('Error updating page:', error);
    }
  };

  const validateFields = () => {
    let isValid = true;
    const newErrors = {}; // collect errors per field

    // If task or fields not loaded, skip validation
    if (!task || !task.task_fields) return true;

    // Go through each field in the task
    task.task_fields.forEach((item) => {
      const config = item.config || {};
      const { required, validation_type } = config;
      const value = answers[item.fieldName] || '';
      fieldsType[item.fieldName] = validation_type || '';

      // 1) Check if required and empty
      if (required && (!value || value.trim() === '')) {
        isValid = false;
        newErrors[item.fieldName] = 'This field is required.';
        return; // proceed to next field
      }

      // 2) If there is a validation_type, check the value
      if (value && validation_type) {
        let regex;
        switch (validation_type) {
          case 'email':
            regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            break;
          case 'alphanumeric':
            regex = /^[a-zA-Z0-9]+$/;
            break;
          case 'alphapetic': // or 'alphabetic'
            regex = /^[a-zA-Z]+$/;
            break;
          case 'URL':
            // Basic URL pattern (you can use a more robust one if needed)
            regex = /^(https?:\/\/)[^\s/$.?#].[^\s]*$/i;
            break;
          default:
            regex = null;
        }

        if (regex && !regex.test(value)) {
          isValid = false;
          newErrors[item.fieldName] = `Invalid ${validation_type} format.`;
        }
      }
    });

    // Store validation errors (if any) in state so you can display them
    setValidationErrors(newErrors);

    return isValid;
  };

  useImperativeHandle(ref, () => ({
    validateFields,
    answers,
    fieldsType,
    setAnswers: (newAnswers) => {
      // Force a re-render with the new answers
      setAnswers(prevAnswers => {
        const updatedAnswers = { ...prevAnswers, ...newAnswers };
        if (onPageUpdate) {
          onPageUpdate(step, updatedAnswers);
        }
        return updatedAnswers;
      });
    }
  }));

  return (
    <div className={styles.formContainer}>
      {task && task.task_fields && (
        <div className={styles.fieldsWrapper}>
          {task.task_fields.map((item, index) => (
            <div key={index}>
              <FormItem
                item={item}
                userTask={userTask}
                answer={answers[item.fieldName]}
                onChange={updateAnswers}
                workflowId={workflowId}
                onSavingStateChange={setIsSaving}
              />
              {validationErrors[item.fieldName] && (
                <p style={{ color: 'red' }}>
                  {validationErrors[item.fieldName]}
                </p>
              )}
            </div>
          ))}
        </div>
      )}


    </div>
  );
});