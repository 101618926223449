import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import BrowseWorkflowCard from '../../components/workflow/BrowseWorkflowCard';
import config from '../../config';

import { FaBell, FaUserCircle, FaSearch } from 'react-icons/fa';
import DashboardTitle from '../../components/common/DashboardTitle';
import NavTabs from '../../components/common/NavTabs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Styles from '../../styles/pages/BrowseWorkflows/BrowseWorkflowList.module.css';

import { useTranslation } from 'react-i18next';

function BrowseWorkflowList() {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  const [workflows, setWorkflows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [addingStatus, setAddingStatus] = useState({});

  const [selectedTab, setSelectedTab] = useState('Browse');
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);

  const toggleProfileDropdown = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  useEffect(() => {
    const fetchWorkflows = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/workflows/`, {
          headers: { Authorization: `Token ${localStorage.getItem('authToken')}` },
        });

        // Filter to only show latest versions
        const latestVersions = response.data.reduce((acc, workflow) => {
          // If workflow is a parent or is marked as latest version
          if (!workflow.parent_workflow || workflow.is_latest) {
            // If this workflow has versions, only add if it's the latest
            const existingParentId = workflow.parent_workflow || workflow.id;
            const existing = acc.find(w => 
              w.id === existingParentId || 
              w.parent_workflow === existingParentId
            );

            if (!existing || workflow.is_latest) {
              // Remove any existing version of this workflow
              acc = acc.filter(w => 
                w.id !== existingParentId && 
                w.parent_workflow !== existingParentId
              );
              // Add this version
              acc.push(workflow);
            }
          }
          return acc;
        }, []);

        setWorkflows(latestVersions);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching workflows:', error);
        setLoading(false);
      }
    };
    fetchWorkflows();
  }, []);

  const handleAddWorkflow = (workflowId) => {
    // Show loading for that card
    setAddingStatus((prev) => ({ ...prev, [workflowId]: true }));

    axios
      .post(
        `${config.apiUrl}/user-workflows/`,
        { workflow_id: workflowId, status: 'active' },
        { headers: { Authorization: `Token ${localStorage.getItem('authToken')}` } }
      )
      .then((response) => {
        // 3) Localize success message
        toast.success(t('browseWorkflows.addedSuccess'));
      })
      .catch((error) => {
        console.error('Failed to add workflow:', error.response?.data || error.message);
        // 4) Localize fail message
        toast.error(t('browseWorkflows.addFail'));
      })
      .finally(() => {
        // Reset
        setAddingStatus((prev) => ({ ...prev, [workflowId]: false }));
      });
  };

  const filteredWorkflows = workflows.filter((workflow) => {
    const nameMatch = workflow.name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const descMatch = workflow.description
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    return nameMatch || descMatch;
  });

  // Build your tabs array with localized label
  const tabs = [
    { label: t('browseWorkflows.browseTab'), link: '/browse-workflows' },
  ];

  return (
    <div className={`${Styles.browseDashboardContainer} ${isArabic ? Styles.rtl : ''}`}>
      <div className={Styles.browseDashboardContent}>
        {/* Page Header */}
        <div className={Styles.browseDashboardHeader}>
          <DashboardTitle
            // 5) Localize page title and search placeholder
            title={t('browseWorkflows.title')}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            searchPlaceholder={t('browseWorkflows.searchPlaceholder')}
          />
          <NavTabs tabs={tabs} selectedTab={selectedTab} onTabSelect={setSelectedTab} />
        </div>

        <div className={Styles.browseWorkflowGridSection}>
          {loading ? (
            <div className={Styles.browseLoadingBlock}>
              <div className={Styles.browseSpinner}></div>
              {/* 6) Localize "Loading workflows..." */}
              <div className={Styles.browseLoadingText}>
                {t('browseWorkflows.loading')}
              </div>
            </div>
          ) : filteredWorkflows.length > 0 ? (
            <div className={Styles.browseWorkflowGrid}>
              {filteredWorkflows.map((workflow) => (
                <BrowseWorkflowCard
                  key={workflow.id}
                  workflow={workflow}
                  isAdding={addingStatus[workflow.id]}
                  onAdd={() => handleAddWorkflow(workflow.id)}
                />
              ))}
            </div>
          ) : (
            <div className={Styles.browseEmptyBlock}>
              <img
                src="https://cdn-icons-png.flaticon.com/512/4076/4076401.png"
                alt="No workflows"
                className={Styles.browseEmptyIcon}
              />
              {/* 7) Localize "No workflows found." */}
              <p className={Styles.browseEmptyText}>
                {t('browseWorkflows.noWorkflows')}
              </p>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default BrowseWorkflowList;
