import React, { useState, useEffect } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable
} from 'react-beautiful-dnd';
import { Edit, Trash2 } from 'lucide-react';
import config from '../../config';
import styles from '../../styles/pages/Profile/ProfilePage.module.css';
import userAvatar from '../../assets/images/workflow_logo.png';
import { toast } from 'react-toastify';


function readFileAsBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}

const MAX_FIELDS_DEFAULT = 10;

const ProfilePage = () => {
  const [sections, setSections] = useState([]);
  const [expandedSections, setExpandedSections] = useState([]);

  // State to track which sections are "showing all" fields
  const [showAllFieldsSectionIds, setShowAllFieldsSectionIds] = useState([]);

  // ====== Field Editing Modal ======
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editFieldData, setEditFieldData] = useState({
    sectionId: null,
    fieldId: null,
    name: '',
    value: '',
    fieldType: ''
  });

  // ====== Section Editing Modal ======
  const [isSectionEditModalOpen, setIsSectionEditModalOpen] = useState(false);
  const [editSectionData, setEditSectionData] = useState({
    sectionId: null,
    name: '',
  });

  // ====== ADD SECTION ======
  const [isAddSectionModalOpen, setIsAddSectionModalOpen] = useState(false);
  const [addSectionData, setAddSectionData] = useState({
    name: '',
  });

  // ====== ADD FIELD ======
  const [isAddFieldModalOpen, setIsAddFieldModalOpen] = useState(false);
  const [addFieldData, setAddFieldData] = useState({
    sectionId: null,
    field_name: '',
    field_value: '',
  });

  const languageOptions = [
    { code: '', label: 'Not set' },
    { code: 'en', label: 'English' },
    { code: 'ar', label: 'Arabic' },
  ];
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    dateOfBirth: '',
    userProfileImage: '',
    preferredLanguage: ''
  });
  const [loading, setLoading] = useState(true);
  const [loadingSections, setLoadingSections] = useState(true);
  const [error, setError] = useState('');
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);


  // --------------------------------------------------------------
  //                 FETCH SECTIONS ON MOUNT
  // --------------------------------------------------------------
  const fetchSections = async () => {
    setLoadingSections(true);
    try {
      const authToken = localStorage.getItem('authToken');
      const resp = await fetch(`${config.apiUrl}/user_info/sections/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${authToken}`
        }
      });
      if (!resp.ok) throw new Error('Failed to fetch sections');
      const data = await resp.json();

      // Sort sections + fields by "order"
      data.sort((a, b) => a.order - b.order);
      data.forEach(sec => {
        sec.fields.sort((f1, f2) => f1.order - f2.order);
      });
      console.log('Fetched sections:', data);
      setSections(data);
      setLoadingSections(false);
    } catch (error) {
      console.error('Error fetching sections:', error);
      setLoadingSections(false);
    }
  };

  useEffect(() => {
    fetchSections();
  }, []);


  useEffect(() => {
    const fetchUserProfile = async () => {
      setLoading(true);
      try {
        const authToken = localStorage.getItem('authToken');
        const response = await fetch(`${config.apiUrl}/user-profile/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${authToken}`
          }
        });
        const data = await response.json();
        console.log('User profile data:', data);
        setUser({
          firstName: data.first_name,
          lastName: data.last_name,
          email: data.email,
          gender: data.gender,
          dateOfBirth: data.date_of_birth,
          userProfileImage: data.user_profile_image,
          preferredLanguage: data.preferred_language
        });
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch user profile:', error);
        setError('Failed to fetch user profile. Please try again.');
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);


  // --------------------------------------------------------------
  //                EXPAND / COLLAPSE SECTIONS
  // --------------------------------------------------------------
  const toggleSection = (sectionId) => {
    setExpandedSections((prev) =>
      prev.includes(sectionId)
        ? prev.filter((id) => id !== sectionId)
        : [...prev, sectionId]
    );
  };

  const isSectionOpen = (sectionId) => expandedSections.includes(sectionId);

  // --------------------------------------------------------------
  //                SHOW MORE / LESS FIELDS
  // --------------------------------------------------------------
  const isShowingAllFields = (sectionId) =>
    showAllFieldsSectionIds.includes(sectionId);

  const toggleShowAllFields = (sectionId) => {
    setShowAllFieldsSectionIds((prev) =>
      prev.includes(sectionId)
        ? prev.filter((id) => id !== sectionId)
        : [...prev, sectionId]
    );
  };

  // --------------------------------------------------------------
  //            DRAG & DROP (REORDER) SECTIONS
  // --------------------------------------------------------------
  const onDragEndSections = async (result) => {
    if (!result.destination) return;

    const newSections = [...sections];
    const [moved] = newSections.splice(result.source.index, 1);
    newSections.splice(result.destination.index, 0, moved);

    // Update "order" for each
    newSections.forEach((sec, idx) => {
      sec.order = idx + 1;
    });
    setSections(newSections);

    // Save each section’s new order
    try {
      for (const sec of newSections) {
        await saveSectionToServer(sec);
      }
    } catch (err) {
      console.error('Error saving reordered sections:', err);
      alert('Failed to save reordered sections.');
    }
  };

  // --------------------------------------------------------------
  //        DRAG & DROP (REORDER) FIELDS WITHIN A SECTION
  // --------------------------------------------------------------
  const onDragEndFields = async (result, sectionIndex) => {
    if (!result.destination) return;

    const updatedSections = [...sections];
    const section = { ...updatedSections[sectionIndex] };
    const fields = [...section.fields];

    const [movedField] = fields.splice(result.source.index, 1);
    fields.splice(result.destination.index, 0, movedField);

    // Recalculate order
    fields.forEach((f, idx) => (f.order = idx + 1));

    section.fields = fields;
    updatedSections[sectionIndex] = section;
    setSections(updatedSections);

    // Save changed section to server
    try {
      await saveSectionToServer(section);
    } catch (err) {
      console.error('Error saving reordered fields:', err);
      alert('Failed to save reordered fields.');
    }
  };

  // --------------------------------------------------------------
  //     SAVE AN ENTIRE SECTION (AFTER REORDER/EDIT/DELETE)
  // --------------------------------------------------------------
  const saveSectionToServer = async (sectionObj) => {
    const payload = {
      id: sectionObj.id,
      name: sectionObj.name,
      order: sectionObj.order,
    };
    const authToken = localStorage.getItem('authToken');
    const resp = await fetch(
      `${config.apiUrl}/user_info/sections/${sectionObj.id}/`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify(payload),
      }
    );
    if (!resp.ok) {
      throw new Error(`Failed to save section ID: ${sectionObj.id}`);
    }
  };

  // --------------------------------------------------------------
  //                 EDIT FIELD (MODAL)
  // --------------------------------------------------------------
  const openEditModal = (sectionId, field) => {
    console.log('Editing field:', field);
    setEditFieldData({
      sectionId,
      fieldId: field.id,
      name: field.field_name,
      value: field.field_value,
      fieldType: field.field_type
    });
    setIsEditModalOpen(true);
  };

  const handleEditFieldInputChange = (key, newVal) => {
    setEditFieldData((prev) => ({ ...prev, [key]: newVal }));
  };

  function validateFieldValue(value, fieldType) {
    if (!fieldType || !value) {
      return null; // No validation if type is empty or no value
    }

    // We define a dictionary of patterns or logic for each type:
    const PATTERNS = {
      email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      alphanumeric: /^[a-zA-Z0-9]+$/,
      alphabetic: /^[a-zA-Z]+$/,
      url: /^(https?:\/\/)[^\s/$.?#].[^\s]*$/i
    };

    const patternKey = fieldType.toLowerCase(); // e.g. 'email', 'url', etc.
    if (patternKey in PATTERNS) {
      const regex = PATTERNS[patternKey];
      if (!regex.test(value)) {
        return `Invalid ${fieldType} format.`;
      }
    }
    // If fieldType is unknown or if the value passes the regex, return null
    return null;
  }

  const handleSaveEditedField = async () => {
    try {
      console.log('Edit field data:', editFieldData);
      const errorMessage = validateFieldValue(editFieldData.value, editFieldData.fieldType);
      if (errorMessage) {
        alert(errorMessage);
        return;
      }
      console.log('Updating field ID:', editFieldData.fieldId);
      const authToken = localStorage.getItem('authToken');
      const resp = await fetch(
        `${config.apiUrl}/user_info/sections-fields/${editFieldData.fieldId}/`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${authToken}`,
          },
          body: JSON.stringify({
            field_name: editFieldData.name,
            field_value: editFieldData.value,
            field_type: editFieldData.fieldType
          }),
        }
      );
      if (!resp.ok) {
        throw new Error(`Failed to update field ID: ${editFieldData.fieldId}`);
      }
      console.log('Field updated successfully.');
      // Close modal
      setIsEditModalOpen(false);
      // Refresh the sections
      await fetchSections();

    } catch (err) {
      console.error('Error deleting field:', err);
      alert('Failed to update field.');
    }
  };

  const handleCancelEditField = () => {
    setIsEditModalOpen(false);
  };

  // --------------------------------------------------------------
  //                DELETE FIELD
  // --------------------------------------------------------------
  const handleDeleteField = async (sectionId, fieldId) => {
    if (!window.confirm('Are you sure you want to delete this field?')) {
      return;
    }
    try {
      console.log('Deleting field ID:', fieldId);
      const authToken = localStorage.getItem('authToken');
      const resp = await fetch(
        `${config.apiUrl}/user_info/sections-fields/${fieldId}/`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${authToken}`,
          },
        }
      );
      if (!resp.ok) {
        throw new Error(`Failed to delete field ID: ${fieldId}`);
      }
      console.log('Field deleted successfully.');
      // Refresh the sections
      await fetchSections();
    } catch (err) {
      console.error('Error deleting field:', err);
      alert('Failed to delete field.');
    }
  };

  const handleDeleteSelectedFields = async () => {
    if (selectedFields.length === 0) return;
    if (!window.confirm('Are you sure you want to delete ALL selected fields?')) {
      return;
    }
    try {
      console.log('Deleting selected fields:', selectedFields);
      const authToken = localStorage.getItem('authToken');
      const resp = await fetch(`${config.apiUrl}/user_info/sections-fields/bulk_delete/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({ field_ids: selectedFields })
      });

      if (!resp.ok) {
        throw new Error(`Failed to bulk-delete fields. Status: ${resp.status}`);
      }

      toast('Selected fields deleted!', { type: 'success' });
      setSelectedFields([]);     // clear the selection
      await fetchSections();     // reload updated data
    } catch (err) {
      console.error('Error bulk-deleting fields:', err);
      alert('Failed to delete selected fields.');
    }
  };


  const handleFieldCheckboxChange = (fieldId) => {
    setSelectedFields((prevSelected) => {
      if (prevSelected.includes(fieldId)) {
        return prevSelected.filter(id => id !== fieldId);
      } else {
        return [...prevSelected, fieldId];
      }
    });
  };

  // --------------------------------------------------------------
  //                EDIT SECTION (MODAL)
  // --------------------------------------------------------------
  const handleEditSectionInputChange = (key, value) => {
    setEditSectionData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleEditSection = (sectionId) => {
    const sectionToEdit = sections.find((sec) => sec.id === sectionId);
    if (!sectionToEdit) return;

    setEditSectionData({
      sectionId: sectionToEdit.id,
      name: sectionToEdit.name,
    });
    setIsSectionEditModalOpen(true);
  };

  const handleSaveEditedSection = async () => {
    try {
      const { sectionId, name } = editSectionData;
      console.log('Updating section ID:', sectionId);
      console.log('New section name:', name);
      const authToken = localStorage.getItem('authToken');
      const resp = await fetch(
        `${config.apiUrl}/user_info/sections/${sectionId}/`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${authToken}`,
          },
          body: JSON.stringify({ name: name })
        }
      );
      console.log('Edit section response:', resp);
      if (!resp.ok) {
        throw new Error(`Failed to edit section ID: ${sectionId}`);
      }

      // Update local state
      const updatedSections = sections.map((sec) =>
        sec.id === sectionId ? { ...sec, name } : sec
      );
      setSections(updatedSections);

      console.log('Section editted successfully.');

      // Close modal
      setIsSectionEditModalOpen(false);
    } catch (err) {
      console.error('Error saving edited section:', err);
      alert('Failed to save section changes.');
    }
  };

  const handleCancelEditSection = () => {
    setIsSectionEditModalOpen(false);
  };

  // --------------------------------------------------------------
  //                DELETE SECTION
  // --------------------------------------------------------------
  const handleDeleteSection = async (sectionId) => {
    if (!window.confirm('Are you sure you want to delete this entire section?')) {
      return;
    }

    try {
      const authToken = localStorage.getItem('authToken');
      const resp = await fetch(
        `${config.apiUrl}/user_info/sections/${sectionId}/`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${authToken}`,
          },
        }
      );

      if (!resp.ok) {
        throw new Error(`Failed to delete section ID: ${sectionId}`);
      }

      const updatedSections = sections.filter((sec) => sec.id !== sectionId);
      setSections(updatedSections);

      console.log('Section deleted successfully.');
    } catch (err) {
      console.error('Error deleting section:', err);
      alert('Failed to delete section.');
    }
  };

  // --------------------------------------------------------------
  //                ADD SECTION LOGIC + MODAL
  // --------------------------------------------------------------
  const openAddSectionModal = () => {
    setAddSectionData({ name: '' });
    setIsAddSectionModalOpen(true);
  };

  const handleAddSectionInputChange = (key, val) => {
    setAddSectionData((prev) => ({ ...prev, [key]: val }));
  };

  const handleCreateSection = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      const resp = await fetch(`${config.apiUrl}/user_info/sections/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({ name: addSectionData.name }),
      });

      if (!resp.ok) {
        throw new Error('Failed to create section');
      }
      toast('Section created successfully', { type: 'success' });

      // Refresh sections
      await fetchSections();
      setIsAddSectionModalOpen(false);
    } catch (err) {
      console.error('Error creating section:', err);
      alert('Failed to create section');
    }
  };

  const handleCancelAddSection = () => {
    setIsAddSectionModalOpen(false);
  };

  // --------------------------------------------------------------
  //                ADD FIELD LOGIC + MODAL
  // --------------------------------------------------------------
  const openAddFieldModal = (sectionId) => {
    setAddFieldData({
      sectionId,
      field_name: '',
      field_value: '',
    });
    setIsAddFieldModalOpen(true);
  };

  const handleAddFieldInputChange = (key, val) => {
    setAddFieldData((prev) => ({ ...prev, [key]: val }));
  };

  const handleCreateField = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      const resp = await fetch(`${config.apiUrl}/user_info/sections-fields/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({
          fields: [{
            section: addFieldData.sectionId,
            field_name: addFieldData.field_name,
            field_value: addFieldData.field_value,
            field_type: addFieldData.field_type || 'text'
          }]
        }),
      });
      if (!resp.ok) {
        throw new Error('Failed to create field');
      }

      toast('Field created successfully', { type: 'success' });
      // Refresh sections
      await fetchSections();
      setIsAddFieldModalOpen(false);

    } catch (err) {
      console.error('Error creating field:', err);
      alert('Failed to create field');
    }
  };

  const handleCancelAddField = () => {
    setIsAddFieldModalOpen(false);
  };


  const handleProfileImageChange = async (event) => {
    if (!event.target.files || event.target.files.length === 0) return;

    const file = event.target.files[0];
    try {
      setIsUploadingImage(true);

      // 1) Convert file to base64
      const base64Image = await readFileAsBase64(file);

      // 2) Submit PATCH to /user-profile/
      const authToken = localStorage.getItem('authToken');
      const patchResponse = await fetch(`${config.apiUrl}/user-profile/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${authToken}`
        },
        body: JSON.stringify({
          user_profile_image: base64Image
        })
      });

      if (!patchResponse.ok) {
        throw new Error('Failed to update profile image');
      }
      // 3) Parse the updated user profile data from the response
      const updatedUserData = await patchResponse.json();

      // 4) Update local state (so the new image is displayed)
      setUser((prev) => ({
        ...prev,
        userProfileImage: updatedUserData.user_profile_image
      }));
    } catch (err) {
      console.error('Error updating profile image:', err);
      alert('Could not update profile image.');
    } finally {
      setIsUploadingImage(false);
    }
  };

  const handlePreferredLanguageChange = async (newLang) => {
    try {
      const authToken = localStorage.getItem('authToken');
      const resp = await fetch(`${config.apiUrl}/user-profile/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${authToken}`,
        },
        body: JSON.stringify({ preferred_language: newLang })
      });
      if (!resp.ok) {
        throw new Error('Failed to update preferred language');
      }

      // On success, update local state
      setUser((prev) => ({ ...prev, preferredLanguage: newLang }));
      toast('Preferred language updated!', { type: 'success' });
    } catch (err) {
      console.error('Error updating preferred language:', err);
      alert('Could not update preferred language.');
    }
  };

  // --------------------------------------------------------------
  //                         RENDER
  // --------------------------------------------------------------
  if (loading) {
    return (
      <div className={styles.loadingBlock}>
        <div className={styles.spinner}></div>
        <div className={styles.loadingText}>
          Loading user profile...
        </div>
      </div>
    );
  }
  return (
    <div className={styles.pageWrapper}>
      {/* Profile Header */}
      <div className={styles.profileHeader}>
        <img
          src={user.userProfileImage}
          alt="User Avatar"
          className={styles.profileAvatar}
        />

        <div className={styles.profileInfo}>
          <h1 className={styles.profileName}>{user.firstName} {user.lastName}</h1>
          <p className={styles.profileSubtitle}>{user.email}</p>
        </div>

        <div style={{ marginLeft: 'auto', marginRight: '20px' }}>
          <label
            htmlFor="profileImageInput"
            className={styles.bigActionBtn}
            style={{ cursor: 'pointer' }}
          >
            {isUploadingImage ? 'Uploading...' : 'Change Picture'}
          </label>
          <input
            id="profileImageInput"
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleProfileImageChange}
          />
        </div>
      </div>

      <div className={styles.container}>
        <h2 className={styles.pageTitle}>My Profile</h2>

        <div style={{ marginBottom: '1.5rem' }}>
          <label style={{ fontWeight: 'bold', marginRight: '8px' }}>
            Preferred Language:
          </label>
          <select
            value={user.preferredLanguage}
            onChange={(e) => {
              const newLang = e.target.value;
              setUser(prev => ({ ...prev, preferredLanguage: newLang }));
              handlePreferredLanguageChange(newLang);
            }}
            style={{ padding: '0.3rem 0.5rem', borderRadius: '4px' }}
          >
            {languageOptions.map((lang) => (
              <option key={lang.code} value={lang.code}>
                {lang.label}
              </option>
            ))}
          </select>
        </div>

        {/* ADD SECTION BUTTON */}
        <button
          onClick={openAddSectionModal}
          className={`${styles.addSectionBtn} ${styles.bigActionBtn}`}
        >
          + Add Section
        </button>

        {selectedFields.length > 0 && (
          <button
            onClick={handleDeleteSelectedFields}
            className={`${styles.bigActionBtn}`}
            style={{ backgroundColor: '#dc2626', color: '#fff', margin: '1rem 1rem' }}
          >
            Delete Selected ({selectedFields.length})
          </button>
        )}


        {loadingSections ? (
          <div className={styles.loadingBlock}>
            <div className={styles.spinner}></div>
            <div className={styles.loadingText}>
              Loading sections...
            </div>
          </div>
        ) : sections.length === 0 ? (
          <div className={styles.noSectionsBlock}>
            <p>No sections found, start a workflows to add a new sections.</p>
          </div>
        ) : (
          <DragDropContext onDragEnd={onDragEndSections}>
            <Droppable droppableId="sections-droppable" type="SECTIONS">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {sections.map((section, sectionIndex) => (
                    <Draggable
                      key={section.id}
                      draggableId={`section-${section.id}`}
                      index={sectionIndex}
                    >
                      {(sectionProvided) => (
                        <div
                          ref={sectionProvided.innerRef}
                          {...sectionProvided.draggableProps}
                          className={styles.sectionCard}
                        >
                          {/* Section Header */}
                          <div
                            className={styles.sectionHeader}
                            {...sectionProvided.dragHandleProps}
                            onClick={() => toggleSection(section.id)}
                          >
                            <div className={styles.sectionHeaderLeft}>
                              {/* Drag handle dots */}
                              <div className={styles.dragHandleIcon} />
                              <div
                                className={styles.sectionHeaderClickArea}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleSection(section.id);
                                }}
                              >
                                <div
                                  className={
                                    isSectionOpen(section.id)
                                      ? `${styles.arrowIcon} ${styles.arrowOpen}`
                                      : styles.arrowIcon
                                  }
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    toggleSection(section.id);
                                  }}
                                />
                                <div className={styles.sectionTitle}>
                                  {section.name}
                                </div>
                              </div>
                            </div>

                            <div className={styles.sectionHeaderRight}>
                              <button
                                className={styles.iconButton}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEditSection(section.id);
                                }}
                              >
                                <Edit size={16} />
                              </button>
                              <button
                                className={styles.iconButton}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteSection(section.id);
                                }}
                              >
                                <Trash2 size={16} color="#ef4444" />
                              </button>
                            </div>
                          </div>

                          {/* Section Body / Fields */}
                          <div
                            className={
                              isSectionOpen(section.id)
                                ? `${styles.sectionBody} ${styles.sectionBodyOpen}`
                                : styles.sectionBody
                            }
                          >
                            {isSectionOpen(section.id) && (
                              <>
                                <DragDropContext
                                  onDragEnd={(result) =>
                                    onDragEndFields(result, sectionIndex)
                                  }
                                >
                                  <Droppable
                                    droppableId={`fields-${section.id}`}
                                    type={`FIELDS-${section.id}`}
                                  >
                                    {(fieldsProvided) => (
                                      <div
                                        ref={fieldsProvided.innerRef}
                                        {...fieldsProvided.droppableProps}
                                      >
                                        {/** Decide how many fields to show */}
                                        {section.fields
                                          .slice(
                                            0,
                                            isShowingAllFields(section.id)
                                              ? section.fields.length
                                              : MAX_FIELDS_DEFAULT
                                          )
                                          .map((field, fieldIndex) => (
                                            <Draggable
                                              key={field.id}
                                              draggableId={`field-${field.id}`}
                                              index={fieldIndex}
                                            >
                                              {(fieldProvided) => (
                                                <div
                                                  ref={fieldProvided.innerRef}
                                                  {...fieldProvided.draggableProps}
                                                  {...fieldProvided.dragHandleProps}
                                                  className={styles.fieldRow}
                                                >
                                                  <input
                                                    type="checkbox"
                                                    style={{ marginRight: '8px' }}
                                                    checked={selectedFields.includes(field.id)}
                                                    onChange={(e) => {
                                                      e.stopPropagation();
                                                      handleFieldCheckboxChange(field.id);
                                                    }}
                                                  />

                                                  <div className={styles.fieldHandleIcon} />
                                                  <div className={styles.fieldContent}>
                                                    <div className={styles.fieldName}>
                                                      {field.field_name || '(No Name)'}
                                                    </div>
                                                    <div className={styles.fieldValue}>
                                                      {field.field_value ? (
                                                        Array.isArray(field.field_value) ? (
                                                          <ul className={styles.bulletedList}>
                                                            {field.field_value.map((item, index) => (
                                                              <li key={index} className={styles.listItem}>
                                                                {item}
                                                              </li>
                                                            ))}
                                                          </ul>
                                                        ) : typeof field.field_value === 'object' ? (
                                                          <pre>{JSON.stringify(field.field_value, null, 2)}</pre>
                                                        ) : (
                                                          field.field_value
                                                        )
                                                      ) : (
                                                        '(No Value)'
                                                      )}
                                                    </div>

                                                  </div>
                                                  <div className={styles.fieldIcons}>
                                                    <button
                                                      className={styles.iconButton}
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        openEditModal(section.id, field);
                                                      }}
                                                    >
                                                      <Edit size={16} />
                                                    </button>
                                                    <button
                                                      className={styles.iconButton}
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDeleteField(section.id, field.id);
                                                      }}
                                                    >
                                                      <Trash2 size={16} color="#ef4444" />
                                                    </button>
                                                  </div>
                                                </div>
                                              )}
                                            </Draggable>
                                          ))}
                                        {fieldsProvided.placeholder}

                                        {/* Show More / Show Less for large field sets */}
                                        {section.fields.length > MAX_FIELDS_DEFAULT && (
                                          <button
                                            className={styles.showMoreBtn}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              toggleShowAllFields(section.id);
                                            }}
                                          >
                                            {isShowingAllFields(section.id)
                                              ? 'Show Less'
                                              : `Show All (${section.fields.length})`}
                                          </button>
                                        )}
                                      </div>
                                    )}
                                  </Droppable>
                                </DragDropContext>

                                {/* ADD FIELD BUTTON */}
                                <button
                                  onClick={() => openAddFieldModal(section.id)}
                                  className={`${styles.addFieldBtn} ${styles.bigActionBtn}`}
                                >
                                  + Add Field
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </div>

      {/* ======================= ADD SECTION MODAL ======================= */}
      {isAddSectionModalOpen && (
        <div className={styles.modalBackdrop}>
          <div className={styles.modalContainer}>
            <div className={styles.modalHeader}>
              <h3>Add New Section</h3>
              <button
                className={styles.closeModalBtn}
                onClick={handleCancelAddSection}
              >
                &times;
              </button>
            </div>
            <div className={styles.modalBody}>
              <label>Section Name</label>
              <input
                type="text"
                value={addSectionData.name}
                onChange={(e) =>
                  handleAddSectionInputChange('name', e.target.value)
                }
                className={styles.modalInput}
              />
            </div>
            <div className={styles.modalFooter}>
              <button
                className={`${styles.btn} ${styles.cancelBtn}`}
                onClick={handleCancelAddSection}
              >
                Cancel
              </button>
              <button
                className={`${styles.btn} ${styles.saveBtn}`}
                onClick={handleCreateSection}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      )}

      {/* ======================= ADD FIELD MODAL ======================= */}
      {isAddFieldModalOpen && (
        <div className={styles.modalBackdrop}>
          <div className={styles.modalContainer}>
            <div className={styles.modalHeader}>
              <h3>Add New Field</h3>
              <button
                className={styles.closeModalBtn}
                onClick={handleCancelAddField}
              >
                &times;
              </button>
            </div>
            <div className={styles.modalBody}>
              <label>Field Name</label>
              <input
                type="text"
                value={addFieldData.field_name}
                onChange={(e) =>
                  handleAddFieldInputChange('field_name', e.target.value)
                }
                className={styles.modalInput}
              />
              <label>Field Value</label>
              <textarea
                value={addFieldData.field_value}
                onChange={(e) =>
                  handleAddFieldInputChange('field_value', e.target.value)
                }
                className={styles.modalTextarea}
              />
            </div>
            <div className={styles.modalFooter}>
              <button
                className={`${styles.btn} ${styles.cancelBtn}`}
                onClick={handleCancelAddField}
              >
                Cancel
              </button>
              <button
                className={`${styles.btn} ${styles.saveBtn}`}
                onClick={handleCreateField}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      )}

      {/* ======================= EDIT SECTION MODAL ====================== */}
      {isSectionEditModalOpen && (
        <div className={styles.modalBackdrop}>
          <div className={styles.modalContainer}>
            <div className={styles.modalHeader}>
              <h3>Edit Section</h3>
              <button
                className={styles.closeModalBtn}
                onClick={handleCancelEditSection}
              >
                &times;
              </button>
            </div>
            <div className={styles.modalBody}>
              <label>Section Name</label>
              <input
                type="text"
                value={editSectionData.name}
                onChange={(e) =>
                  handleEditSectionInputChange('name', e.target.value)
                }
                className={styles.modalInput}
              />
            </div>
            <div className={styles.modalFooter}>
              <button
                className={`${styles.btn} ${styles.cancelBtn}`}
                onClick={handleCancelEditSection}
              >
                Cancel
              </button>
              <button
                className={`${styles.btn} ${styles.saveBtn}`}
                onClick={handleSaveEditedSection}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {/* ======================= EDIT FIELD MODAL ====================== */}
      {isEditModalOpen && (
        <div className={styles.modalBackdrop}>
          <div className={styles.modalContainer}>
            <div className={styles.modalHeader}>
              <h3>Edit Field</h3>
              <button
                className={styles.closeModalBtn}
                onClick={handleCancelEditField}
              >
                &times;
              </button>
            </div>
            <div className={styles.modalBody}>
              <label>Field Name</label>
              <input
                type="text"
                value={editFieldData.name}
                onChange={(e) => handleEditFieldInputChange('name', e.target.value)}
                className={styles.modalInput}
              />
              <label>Field Value</label>
              <textarea
                value={editFieldData.value}
                onChange={(e) => handleEditFieldInputChange('value', e.target.value)}
                className={styles.modalTextarea}
              />
              <label>Field Type</label>
              <select
                value={editFieldData.fieldType}
                onChange={(e) => handleEditFieldInputChange('fieldType', e.target.value)}
                className={styles.modalInput}
              >
                <option value="">Text (none specified)</option>
                <option value="email">Email</option>
                <option value="alphanumeric">Alphanumeric</option>
                <option value="alphabetic">Alphabetic</option>
                <option value="url">URL</option>
                {/* Add more if needed */}
              </select>
            </div>
            <div className={styles.modalFooter}>
              <button
                className={`${styles.btn} ${styles.cancelBtn}`}
                onClick={handleCancelEditField}
              >
                Cancel
              </button>
              <button
                className={`${styles.btn} ${styles.saveBtn}`}
                onClick={handleSaveEditedField}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfilePage;
