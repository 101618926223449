import TextField, { TextFieldConfig } from "./TextField";
import CheckboxField, { CheckboxFieldConfig } from "./CheckboxField";
import SpinnerField, { SpinnerFieldConfig } from "./SpinnerField";
import RadioButtonField, { RadioButtonFieldConfig } from "./RadioButtonField";
import FileUpload, { FileUploadConfig } from "./FileUpload";
import NumberField, { NumberFieldConfig } from "./NumberField";
import DateField, { DateFieldConfig } from "./DateField";
import MultiSelectField, { MultiSelectFieldConfig } from "./MultiSelectField";
import SignatureField, { SignatureFieldConfig } from "./SignatureField";
import TextAreaField, { TextAreaFieldConfig } from "./TextAreaField";

// Descriptions for each configuration key
export const descriptions = {
  fieldLabel: "Enter the label for this field",
  required: "Prevent submission if this field is empty",
  help_text: "Add a short description below the field",
  placeholder: "Add placeholder text inside the field box",
  max_length: "Set the maximum number of characters allowed",
  default_value: "Set the default value for this field",
  default_value_spinner: "Choose the default value for this field",
  default_value_multiChoice: "Choose the default value/s for this field",
  default_value_checkbox: "Choose the default value for this field (checked or unchecked)",
  default_value_number: "Choose the default value for this field",
  default_values_checkboxes: "Choose the default value/s for this field",
  choices: "Add choices for users to select from (one per line)",
  max_file_size: "Set the maximum file size allowed (in MB)",
  allowed_file_types: "Set the allowed file types (e.g. .pdf, .docx) separated by commas. Leave blank to allow all file types",
  min_value: "Set the minimum value allowed",
  max_value: "Set the maximum value allowed",
  min_date: "Set the minimum date allowed",
  max_date: "Set the maximum date allowed",
  default_value_date: "Choose the default date for this field",
  min_selections: "Set the minimum number of choices allowed",
  max_selections: "Set the maximum number of choices allowed",
  allowUpload: "Allow user to upload an image as a signature.",
  allowTyping: "Allow user to type their name as a signature (with optional fonts).",
  validation_type: "Enforce a specific validation type for this field",
  rows: "Set the number of visible rows for the textarea",
};

// Labels for each configuration key
export const labels = {
  fieldLabel: "Field Label",
  required: "Required",
  help_text: "Help Text",
  placeholder: "Placeholder",
  max_length: "Max Length",
  default_value: "Default Value",
  default_value_spinner: "Default Value",
  default_value_multiChoice: "Default Value",
  default_value_checkbox: "Default Value",
  default_value_number: "Default Value",
  choices: "Choices",
  max_file_size: "Max File Size",
  allowed_file_types: "Allowed File Types",
  min_value: "Min Value",
  max_value: "Max Value",
  min_date: "Min Date",
  max_date: "Max Date",
  default_value_date: "Default Value",
  default_values_checkboxes: "Default Values",
  min_selections: "Min Selections",
  max_selections: "Max Selections",
  allowUpload: "Allow Upload",
  allowTyping: "Allow Typing",
  validation_type: "Validation",
  rows: "Number of Rows",
};

// Types (General or Advanced) for each configuration key
export const types = {
  fieldLabel: "general",
  required: "general",
  choices: "general",
  min_value: "general",
  max_value: "general",
  min_date: "general",
  max_date: "general",
  min_selections: "general",
  max_selections: "general",
  allowUpload: "general",
  allowTyping: "general",
  rows: "general",

  help_text: "advanced",
  placeholder: "advanced",
  max_length: "advanced",
  default_value: "advanced",
  default_value_spinner: "advanced",
  default_value_multiChoice: "advanced",
  default_value_checkbox: "advanced",
  default_value_number: "advanced",
  max_file_size: "advanced",
  allowed_file_types: "advanced",
  default_value_date: "advanced",
  default_values_checkboxes: "advanced",
  validation_type: "advanced",

};

// Field types (input types) for each configuration key
export const fieldTypes = {
  fieldLabel: "text",
  required: "checkbox",
  help_text: "text",
  placeholder: "text",
  max_length: "number",
  default_value: "text",
  default_value_spinner: "spinner",
  default_value_multiChoice: "multi_checkbox",
  default_value_checkbox: "checkbox",
  default_value_number: "number",
  choices: "textarea",
  max_file_size: "number",
  allowed_file_types: "text",
  min_value: "number",
  max_value: "number",
  min_date: "date",
  max_date: "date",
  default_value_date: "date",
  default_values_checkboxes: "multi_checkbox",
  min_selections: "number",
  max_selections: "number",
  allowUpload: "checkbox",
  allowTyping: "checkbox",
  validation_type: "spinner",
  rows: "number",
};

export const fieldConfigs = {
  text: TextFieldConfig,
  checkbox: CheckboxFieldConfig,
  spinner: SpinnerFieldConfig,
  radiobutton: RadioButtonFieldConfig,
  file_upload: FileUploadConfig,
  number: NumberFieldConfig,
  date: DateFieldConfig,
  multi_select_checkboxes: MultiSelectFieldConfig,
  signature: SignatureFieldConfig,
  textarea: TextAreaFieldConfig,
};

export const fieldTypeComponents = {
  text: TextField,
  checkbox: CheckboxField,
  spinner: SpinnerField,
  radiobutton: RadioButtonField,
  file_upload: FileUpload,
  number: NumberField,
  date: DateField,
  multi_select_checkboxes: MultiSelectField,
  signature: SignatureField,
  textarea: TextAreaField,
};
