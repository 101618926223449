import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import NavbarAddWorkflow from '../../components/Navbars/NavbarAddWorkflow';
import WorkflowBuilder from '../../components/WorkflowBuilder/WorkflowBuilder';
import WorkflowSettings from '../../components/WorkflowBuilder/WorkflowSettings';
import AdminDependenciesView from '../../components/WorkflowBuilder/AdminDependenciesView';
import AIAssistant from '../../components/WorkflowBuilder/AIAssistant';
import PublishWorkflowModal from '../../components/Modals/PublishWorkflowModal';


import axios from 'axios';
import config from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';
import { ReactFlowProvider } from "reactflow";
import { toast } from 'react-toastify';

import styles from '../../styles/pages/AdminDashboard/AddWorkflow.module.css';

const AIInstruction = `
[Generate a JSON workflow strictly. No extra text, only JSON. The workflow should be rich, containing multiple detailed tasks and multiple fields per task to ensure completeness. 

1. **Interpret User Input Dynamically**:
   - If the input is can be **workflow description** (whether structured or unstructured), **extract** tasks, approvals, and dependencies to build a detailed workflow.
   - If the input is **completely not workflow-related** (e.g., random questions, coding requests), return an error message.

2. **Rich Workflow Generation**:
   - Generate at least **5-10 tasks** with detailed steps.
   - Each task must include **3-5 fields**.
   - Maintain **logical dependencies** between tasks.

3. **Language Adaptation**:
   - Respond in the same user request language. Unless user specifies a language.
   - If the user’s request is in **Arabic**, return the workflow in **Arabic**.
   - If the user’s request is in **English**, return the workflow in **English**.
   - If user typed in multiple languages, **choose the most frequent** language.
   - Make The selected language applied to all fields and config values and tasks.


4. **Validation**:
   - If the request **contains a workflow description**, process it.
   - If the request is **completely unrelated** to workflow creation, return:
     \`\`\`json
     {
         "success": false,
         "summary": "Error: This request is outside the allowed scope of workflow generation."
     }
     \`\`\`
   - If the request is **ambiguous** but could be a workflow, attempt to infer and generate a reasonable workflow.


**Response Format:**
\`\`\`json
{
    "success": true,
    "name": "[Workflow name]",
    "description": "[Max 100 chars]",
    "type": "primitive",
    "summary": "[3-5 sentence summary]",
    "tasks": [
        {
            "name": "[Task name]",
            "description": "[Task description, ensuring clarity and depth]",
            "status": "Active",
            "task_fields": [
                {
                    "fieldTypeID": "[One of: text|checkbox|spinner|radiobutton|number|date|file_upload|MultiSelect|signature]",
                    "index": [number],
                    "config": { [Must match exactly one of the given field configs without changes] }
                },
                ...
            ]
        },
        ...
    ],
    "dependencies": [
        {
            "from_task": [taskIndex],
            "to_task": [taskIndex],
            "dependency_type": "[One of: must_submit_before_view|can_view_only_before_submit|no_restriction]"
        },
        ...
    ]
}
\`\`\`

**Field Configs (no changes, must include all keys for each field type but can leave some values empty)(the values are examples here):**
- **Text:** \`{"fieldLabel":"Enter your name","required":true,"help_text":"Full name appears on official documents","placeholder":"e.g., John Doe","max_length":100,"default_value":""}\`
- **Checkbox:** \`{"fieldLabel":"I agree to the terms and conditions","required":true,"help_text":"Please confirm your agreement","default_value_checkbox":false}\`
- **Radiobutton:** \`{"fieldLabel":"Select your gender","required":true,"help_text":"Choose one of the choices","choices":"Male\\nFemale","default_value":"Male"}\`
- **Spinner:** \`{"fieldLabel":"Select your country","required":true,"help_text":"Choose the country you reside in","choices":"Palestine\\nJordan\\nEgypt\\nLebanon","default_value":"Palestine"}\`
- **Number:** \`{"fieldLabel":"Enter your age","required":true,"placeholder":"e.g., 18","help_text":"Your current age","min_value":0,"max_value":120,"default_value":18}\`
- **Date:** \`{"fieldLabel":"Select appointment date","required":true,"help_text":"Choose a date for your appointment","min_date":"2023-01-01","max_date":"2024-12-31","default_value":"2023-01-01"}\`
- **File Upload:** \`{"fieldLabel":"Upload your resume","required":true,"help_text":"Supported formats: PDF, DOCX","allowed_file_types":"pdf, docx","max_file_size":5}\`
- **multi_select_checkboxes:** \`{"fieldLabel":"Select your hobbies","required":true,"help_text":"You can select multiple choices","choices":"Reading\\nTraveling\\nCooking\\nSports","default_values_checkboxes":"Reading\\nMusic","min_selections":2,"max_selections":3}\`
- **Signature:** \`{"fieldLabel":"Sign here","required":true,"help_text":"Please sign here to confirm your agreement", "allowUpload":true,"allowTyping":true}\`
For Field Configs values use the appropriate language for the user request.
`;

const EditWorkflow = () => {
  const { id } = useParams();

  // =================== MAIN WORKFLOW STATE ===================
  const [tasks, setTasks] = useState([]);
  const [workflowName, setWorkflowName] = useState('');
  const [workflowDescription, setWorkflowDescription] = useState('');
  const [workflowDetailedDescription, setWorkflowDetailedDescription] = useState('');

  // Additional fields for Settings
  const [category, setCategory] = useState('IT');
  const [workflowImage, setWorkflowImage] = useState(null);
  const [visibility, setVisibility] = useState('public');
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [hasDeadline, setHasDeadline] = useState(false);
  const [deadline, setDeadline] = useState(null);
  const [limitUsers, setLimitUsers] = useState(false);
  const [maxUsers, setMaxUsers] = useState(1);
  const [allowMultipleSubmissions, setAllowMultipleSubmissions] = useState(false);
  const [submissionLimit, setSubmissionLimit] = useState(1);

  // ========== GEMINI (AI) CHAT STATE ==========
  const [showGeminiChat, setShowGeminiChat] = useState(false);
  const [showOriginalNavbar, setShowOriginalNavbar] = useState(true);
  const [currentAIInstruction, setCurrentAIInstruction] = useState(AIInstruction);

  // Active tab: 'build' | 'settings' | 'dependencies'
  const [currentTab, setCurrentTab] = useState('build');

  // Dependencies
  const [dependencies, setDependencies] = useState([]);
  const [hasInitializedDeps, setHasInitializedDeps] = useState(false);

  // ──────────────────────────────────────────────────────
  //  LOADING STATES
  // ──────────────────────────────────────────────────────
  const [isLoadingWorkflow, setIsLoadingWorkflow] = useState(true);
  const [isUpdatingWorkflow, setIsUpdatingWorkflow] = useState(false);
  const [isGeneratingAI, setIsGeneratingAI] = useState(false);

  // ──────────────────────────────────────────────────────
  //  PUBLISH MODAL STATE
  // ──────────────────────────────────────────────────────
  const [showPublishModal, setShowPublishModal] = useState(false); // [MOD] Add a modal flag
  const [publishType, setPublishType] = useState('updateCurrent'); // [MOD] Add a publish type
  const [notifyUsers, setNotifyUsers] = useState(false); // [MOD] Notify users who use the old version

  // ──────────────────────────────────────────────────────
  //  FETCH WORKFLOW ON LOAD
  // ──────────────────────────────────────────────────────
  useEffect(() => {
    const fetchWorkflow = async () => {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        toast.error('You are not authenticated. Please login.');
        setIsLoadingWorkflow(false);
        return;
      }
      try {
        const response = await axios.get(`${config.apiUrl}/workflows/${id}`, {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        });
        const workflow = response.data;
        setWorkflowInStateFromBackend(workflow);
      } catch (error) {
        console.error(error);
        toast.error('Failed to load workflow. Please try again.');
      } finally {
        setIsLoadingWorkflow(false);
      }
    };
    fetchWorkflow();
  }, [id]);

  // ──────────────────────────────────────────────────────
  //  UPDATE GEMINI INSTRUCTION WHEN WORKFLOW CHANGES
  // ──────────────────────────────────────────────────────
  useEffect(() => {
    const partialWorkflow = buildPartialWorkflow();
    setCurrentAIInstruction(
      `${AIInstruction}\n\nCurrent Workflow:\n${JSON.stringify(partialWorkflow, null, 2)}\n` +
      `Update fields or add tasks if requested...`
    );
  }, [
    tasks,
    dependencies,
    workflowName,
    workflowDescription,
    workflowDetailedDescription,
    category,
    visibility,
    emailNotifications,
    hasDeadline,
    deadline,
    limitUsers,
    maxUsers,
    allowMultipleSubmissions,
    submissionLimit,
  ]);

  // Build partial workflow (AI usage)
  const buildPartialWorkflow = () => {
    const partialTasks = tasks.map((task, taskIndex) => ({
      name: task.name,
      description: task.description,
      status: 'Active',
      task_fields: task.fields.map((field, idx) => ({
        fieldTypeID: field.type.toLowerCase(),
        index: idx,
        config: { ...field.config },
      })),
    }));

    // Convert IDs to indexes for dependencies
    const indexMap = {};
    tasks.forEach((t, idx) => {
      indexMap[t.id] = idx;
    });
    const partialDependencies = dependencies.map((dep) => ({
      from_task: indexMap[dep.from_task],
      to_task: indexMap[dep.to_task],
      dependency_type: dep.dependency_type,
    }));

    return {
      success: true,
      name: workflowName,
      description: workflowDescription,
      type: 'primitive',
      summary: 'Auto-generated partial summary.',
      tasks: partialTasks,
      dependencies: partialDependencies,
    };
  };

  // ──────────────────────────────────────────────────────
  //  UPDATE WORKFLOW (PUT)
  // ──────────────────────────────────────────────────────
  const updateWorkflow = async (publishType, notifyUsers) => {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      toast.error('You are not authenticated. Please login.');
      return;
    }
  
    setIsUpdatingWorkflow(true);
  
    console.log('Current publish type before API call:', publishType); // Add this log
  
    // 1) tasks
    const taskData = tasks.map((task) => ({
      name: task.name,
      description: task.description,
      status: 'Active',
      task_fields: task.fields.map((field, fieldIndex) => {
        const { fieldLabel, required, ...restConfig } = field.config;
        return {
          fieldTypeID: field.type.toLowerCase(),
          fieldName: fieldLabel || '',
          required: required || false,
          index: fieldIndex,
          config: restConfig,
        };
      }),
    }));
  
    // 2) dependencies
    const indexMap = {};
    tasks.forEach((t, idx) => {
      indexMap[t.id] = idx;
    });
    const dependencyData = dependencies.map((dep) => ({
      from_index: indexMap[dep.from_task],
      to_index: indexMap[dep.to_task],
      dependency_type: dep.dependency_type,
    }));
  
    // 3) final object
    const workflowData = {
      name: workflowName,
      description: workflowDescription,
      detailed_description: workflowDetailedDescription,
      type: 'primitive',
      category: category,
      status: visibility,
      email_notifications: emailNotifications,
      has_deadline: hasDeadline,
      deadline: deadline,
      limit_users: limitUsers,
      max_users: maxUsers,
      allow_multiple_submissions: allowMultipleSubmissions,
      submission_limit: submissionLimit,
      tasks: taskData,
      dependencies: dependencyData,
  
      // Here is the key line: 
      publish_type: publishType,
      notify_users: notifyUsers,
    };
  
    if (workflowImage) {
      workflowData.image_base64 = workflowImage;
    }
  
    try {
      console.log('Sending workflowData with publish_type:', workflowData.publish_type); // Add this log
      
      const response = await axios.put(`${config.apiUrl}/workflows/${id}/`, workflowData, {
        headers: {
          Authorization: `Token ${authToken}`,
          'Content-Type': 'application/json',
        },
      });
  
      toast.success('Workflow updated successfully!', { autoClose: 1500 });
      
      // Add a small delay before redirect to allow the toast to be seen
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      // Prevent multiple redirects
      if (!window.isRedirecting) {
        window.isRedirecting = true;
        window.location.href = '/admin-dashboard';
      }
  
    } catch (error) {
      console.error('Failed to update workflow:', error);
      const errorMessage =
        error.response?.data?.message ||
        'An unknown error occurred. Please check your input and try again.';
      toast.error(`Failed to update workflow: ${errorMessage}`);
    } finally {
      setIsUpdatingWorkflow(false);
    }
  };

  // ──────────────────────────────────────────────────────
  //  SET STATE FROM BACKEND (GET)
  // ──────────────────────────────────────────────────────
  const setWorkflowInStateFromBackend = (workflow) => {
    setWorkflowName(workflow.name || '');
    setWorkflowDescription(workflow.description || '');
    setWorkflowDetailedDescription(workflow.detailed_description || '');
    setCategory(workflow.category || 'IT');
    setVisibility(workflow.status || 'public');
    setEmailNotifications(workflow.email_notifications || false);
    setHasDeadline(workflow.has_deadline || false);
    setDeadline(workflow.deadline || null);
    setLimitUsers(workflow.limit_users || false);
    setMaxUsers(workflow.max_users || 1);
    setAllowMultipleSubmissions(workflow.allow_multiple_submissions || false);
    setSubmissionLimit(workflow.submission_limit || 1);

    // Tasks
    const newTasks = (workflow.tasks || []).map((task) => ({
      id: uuidv4(),
      name: task.name,
      description: task.description,
      fields: (task.task_fields || []).map((f) => ({
        id: uuidv4(),
        type: f.fieldTypeID.charAt(0).toUpperCase() + f.fieldTypeID.slice(1),
        config: {
          fieldLabel: f.fieldName || '',
          required: f.required || false,
          ...f.config,
        },
      })),
    }));
    setTasks(newTasks);

    // Dependencies
    const newDependencies = (workflow.dependencies || []).map((dep) => {
      const fromTask = newTasks[dep.from_index]?.id;
      const toTask = newTasks[dep.to_index]?.id;
      return {
        from_task: fromTask,
        to_task: toTask,
        dependency_type: dep.dependency_type,
      };
    });
    setDependencies(newDependencies);
  };

  // ──────────────────────────────────────────────────────
  // AI: ON GENERATED WORKFLOW
  // ──────────────────────────────────────────────────────
  const handleGeneratedWorkflowFromAI = (parsedWorkflow) => {
    setIsGeneratingAI(true);

    setTimeout(() => {
      setWorkflowName(parsedWorkflow.name);
      setWorkflowDescription(parsedWorkflow.description);
      setWorkflowDetailedDescription('(AI) Detailed description here...');

      const aiTasks = (parsedWorkflow.tasks || []).map((task) => ({
        id: uuidv4(),
        name: task.name,
        description: task.description,
        fields: (task.task_fields || []).map((field) => ({
          id: uuidv4(),
          type: field.fieldTypeID.charAt(0).toUpperCase() + field.fieldTypeID.slice(1),
          config: {
            fieldLabel: field.fieldName || '',
            required: field.required || false,
            ...field.config,
          },
        })),
      }));
      setTasks(aiTasks);

      // Convert dependency indices to UUIDs
      const newDeps = (parsedWorkflow.dependencies || []).map((dep) => ({
        from_task: aiTasks[dep.from_task]?.id,
        to_task: aiTasks[dep.to_task]?.id,
        dependency_type: dep.dependency_type,
      }));
      setDependencies(newDeps);

      setIsGeneratingAI(false);
    }, 1000);
  };

  // ──────────────────────────────────────────────────────
  // TOGGLE GEMINI CHAT
  // ──────────────────────────────────────────────────────
  const toggleGeminiChat = () => {
    setShowGeminiChat(!showGeminiChat);
  };

  // ──────────────────────────────────────────────────────
  // CHOOSE WHAT TO DO WHEN USER FINISHES MODAL (PUBLISH)
  // ──────────────────────────────────────────────────────
  const handlePublishChoice = ({ publishType: newPublishType, notifyUsers: newNotifyUsers }) => {
    setShowPublishModal(false);
    
    // Set the state directly without any transformation
    setPublishType(newPublishType);
    setNotifyUsers(newNotifyUsers);
  
    // Use the value directly in a timeout to ensure state is updated
    setTimeout(() => {
      console.log('About to call updateWorkflow with publishType:', newPublishType);
      updateWorkflow(newPublishType, newNotifyUsers);
    }, 100);
  };

  // ──────────────────────────────────────────────────────
  // SWITCH TABS
  // ──────────────────────────────────────────────────────
  const renderTabContent = () => {
    switch (currentTab) {
      case 'settings':
        return (
          <WorkflowSettings
            workflowImage={workflowImage}
            setWorkflowImage={setWorkflowImage}
            workflowName={workflowName}
            setWorkflowName={setWorkflowName}
            workflowDescription={workflowDescription}
            setWorkflowDescription={setWorkflowDescription}
            detailedDescription={workflowDetailedDescription}
            setDetailedDescription={setWorkflowDetailedDescription}
            category={category}
            setCategory={setCategory}
            visibility={visibility}
            setVisibility={setVisibility}
            emailNotifications={emailNotifications}
            setEmailNotifications={setEmailNotifications}
            hasDeadline={hasDeadline}
            setHasDeadline={setHasDeadline}
            deadline={deadline}
            setDeadline={setDeadline}
            limitUsers={limitUsers}
            setLimitUsers={setLimitUsers}
            maxUsers={maxUsers}
            setMaxUsers={setMaxUsers}
            allowMultipleSubmissions={allowMultipleSubmissions}
            setAllowMultipleSubmissions={setAllowMultipleSubmissions}
            submissionLimit={submissionLimit}
            setSubmissionLimit={setSubmissionLimit}
            showOriginalNavbar={showOriginalNavbar}
          />
        );

      case 'dependencies':
        if (!hasInitializedDeps && tasks.length > 1 && dependencies.length === 0) {
          const newDeps = [];
          for (let i = 0; i < tasks.length - 1; i++) {
            newDeps.push({
              from_task: tasks[i].id,
              to_task: tasks[i + 1].id,
              dependency_type: 'no_restriction',
            });
          }
          setDependencies(newDeps);
          setHasInitializedDeps(true);
        }

        return (
          <div>
            <h2 className={styles.dependencyPageTitle}>Task Dependencies</h2>
            <p className={styles.dependencyPageDescription}>
              Define how tasks connect and set what users can do at each step.
              You can manage dependencies visually in the diagram or directly in the table below.
            </p>
            <ReactFlowProvider>
              <AdminDependenciesView
                tasks={tasks}
                dependencies={dependencies}
                setDependencies={setDependencies}
              />
            </ReactFlowProvider>
          </div>
        );

      case 'build':
      default:
        return (
          <WorkflowBuilder
            tasks={tasks}
            setTasks={setTasks}
            workflowName={workflowName}
            setWorkflowName={setWorkflowName}
            workflowDescription={workflowDescription}
            setWorkflowDescription={setWorkflowDescription}
            workflowDetailedDescription={workflowDetailedDescription}
            setWorkflowDetailedDescription={setWorkflowDetailedDescription}
            showOriginalNavbar={showOriginalNavbar}
          />
        );
    }
  };

  // ──────────────────────────────────────────────────────
  // RENDER
  // ──────────────────────────────────────────────────────
  return (
    <div>
      {/* Overlays for 1) Loading initial workflow, 2) Updating workflow, 3) Generating from AI */}
      {(isLoadingWorkflow || isUpdatingWorkflow || isGeneratingAI) && (
        <div
          className={
            isGeneratingAI
              ? styles.aiOverlay  // behind the AI chat
              : styles.loadingOverlay
          }
        >
          <div className={styles.spinner}></div>
          {isLoadingWorkflow && <p>Loading workflow...</p>}
          {isUpdatingWorkflow && publishType === 'updateCurrent' && <p>Updating Current Workflow...</p>}
          {isUpdatingWorkflow && publishType === 'newVersion' && <p>Creating New Version...</p>}
          {isGeneratingAI && <p>Generating from AI...</p>}
        </div>
      )}

      {/* Pass a function to open the modal rather than directly updating */}
      <NavbarAddWorkflow
        setShowOriginalNavbar={setShowOriginalNavbar}
        showOriginalNavbar={showOriginalNavbar}
        onPublish={() => setShowPublishModal(true)} // [MOD] Show the publish modal
        currentTab={currentTab}
        onTabChange={(tabName) => setCurrentTab(tabName)}
      />

      <div className={styles.spacer} />

      {renderTabContent()}

      {/* This "Update Workflow" button is optional if you want a separate button */}
      <button onClick={() => setShowPublishModal(true)} className={styles.createWorkflowButton}>
        Update / Publish Workflow
      </button>

      {/* Gemini Floating Button */}
      <div className={styles.geminiButton} onClick={toggleGeminiChat}>
        <FontAwesomeIcon icon={faRobot} />
      </div>

      {/* Gemini Chat Window */}
      <AIAssistant
        showChat={showGeminiChat}
        toggleChat={toggleGeminiChat}
        onGenerateContent={handleGeneratedWorkflowFromAI}
        instruction={currentAIInstruction}
      />

      {/* [MOD] Publish Modal */}
      <PublishWorkflowModal
        show={showPublishModal}
        onClose={() => setShowPublishModal(false)}
        onPublish={handlePublishChoice}
      />
    </div>
  );
};

export default EditWorkflow;