import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import UserWorkflowCard from '../../components/workflow/UserWorkflowCard';
import DashboardTitle from '../../components/common/DashboardTitle';
import NavTabs from '../../components/common/NavTabs';
import DeleteConfirmationModal from '../../components/Modals/DeleteConfirmationModal';
import config from '../../config';
import Styles from '../../styles/pages/UserDashboard/UserWorkflowList.module.css';
import LoadingOverlay from '../../components/common/LoadingOverlay'; // Add this import

import { useTranslation } from 'react-i18next';

function UserWorkflowList() {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  const [workflows, setWorkflows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [workflowToDelete, setWorkflowToDelete] = useState(null);
  const [selectedTab, setSelectedTab] = useState('Workflows');
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();

  // Handle delete
  const handleDelete = (workflow) => {
    setWorkflowToDelete(workflow);
    setIsModalOpen(true);
  };

  const confirmDelete = async () => {
    if (!workflowToDelete) return;
    setIsDeleting(true);
    try {
      await axios.delete(`${config.apiUrl}/user-workflows/${workflowToDelete.id}/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('authToken')}`,
        },
      });
      setWorkflows((prev) => prev.filter((w) => w.id !== workflowToDelete.id));
    } catch (error) {
      console.error('Failed to delete the workflow:', error);
    } finally {
      setIsDeleting(false);
      setIsModalOpen(false);
    }
  };

  const cancelDelete = () => {
    setIsModalOpen(false);
  };

  // Fetch workflows
  useEffect(() => {
    const fetchWorkflows = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/user-workflows/`, {
          headers: {
            Authorization: `Token ${localStorage.getItem('authToken')}`,
          },
        });
        setWorkflows(response.data);
      } catch (error) {
        console.error('Error fetching workflows:', error);
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('authToken');
          window.location.href = '/login';
        }
      } finally {
        setLoading(false);
      }
    };
    fetchWorkflows();
  }, []);

  // Filter logic
  const filteredWorkflows = workflows.filter((wf) =>
    wf.workflow?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // On card click
  const handleWorkflowClick = (workflow) => {
    navigate(`/user-dashboard/workflows/${workflow.id}`);
  };

  // Build your tabs array with localized label
  const tabs = [
    { label: t('userWorkflowList.workflowsTab'), link: '/user-dashboard' }
  ];

  return (
    <div className={`${Styles.outerDashboardContainer} ${isArabic ? Styles.rtl : ''}`}>
      <div className={Styles.innerDashboardContent}>
        {/* Header with title and tabs */}
        <div className={Styles.dashboardHeader}>
          <DashboardTitle
            // localize the "Dashboard" text
            title={t('userWorkflowList.dashboardTitle')}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            searchPlaceholder={t('userWorkflowList.searchPlaceholder')}
          />
          <NavTabs
            tabs={tabs}
            selectedTab={selectedTab}
            onTabSelect={setSelectedTab}
          />
        </div>

        {/* Main content: Workflow cards */}
        <div className={Styles.workflowGridSection}>
          {loading ? (
            <div className={Styles.loadingBlock}>
              <div className={Styles.spinner}></div>
              <div className={Styles.loadingText}>
                {t('userWorkflowList.loading')}
              </div>
            </div>
          ) : filteredWorkflows.length > 0 ? (
            <div className={Styles.workflowGrid}>
              {filteredWorkflows.map((workflow) => (
                <UserWorkflowCard
                  key={workflow.id}
                  workflow={workflow}
                  isUserDashboard={true}
                  onDelete={() => handleDelete(workflow)}
                  onClick={() => handleWorkflowClick(workflow)}
                />
              ))}
            </div>
          ) : (
            <div className={Styles.emptyBlock}>
              <img
                src="https://cdn-icons-png.flaticon.com/512/4076/4076401.png"
                alt={t('userWorkflowList.noWorkflowsAlt')} // Localized alt text
                className={Styles.emptyIcon}
              />
              <p className={Styles.emptyText}>
                {t('userWorkflowList.noWorkflowsFound')}
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {workflowToDelete && (
        <DeleteConfirmationModal
          isOpen={isModalOpen}
          onClose={cancelDelete}
          onConfirm={confirmDelete}
          // if you want to localize the word "Workflow" or combine with the name, you can do:
          workflowName={workflowToDelete.workflow.name}
        />
      )}

      {isDeleting && <LoadingOverlay />}
    </div>
  );
}

export default UserWorkflowList;
