import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaInfoCircle, FaCheckCircle } from 'react-icons/fa';
import styles from '../../styles/components/Modals/AutofillConfirmModal.module.css';

export const AutofillConfirmModal = ({
  suggestions,
  selectedFields,
  setSelectedFields,
  onConfirm,
  onCancel
}) => {
  const { t } = useTranslation();

  // Initialize selections with first choice for each field
  useEffect(() => {
    const initialSelections = {};
    const groupedSuggestions = suggestions.reduce((acc, suggestion) => {
      if (!acc[suggestion.task_field_name]) {
        acc[suggestion.task_field_name] = [];
      }
      acc[suggestion.task_field_name].push(suggestion);
      return acc;
    }, {});

    // For each field, select the first suggestion after sorting by confidence
    Object.entries(groupedSuggestions).forEach(([fieldName, fieldSuggestions]) => {
      const sortedSuggestions = [...fieldSuggestions].sort((a, b) => {
        const confidenceOrder = { high: 3, medium: 2, low: 1 };
        return confidenceOrder[b.confidence] - confidenceOrder[a.confidence];
      });

      if (sortedSuggestions.length > 0) {
        const firstSuggestion = sortedSuggestions[0];
        initialSelections[fieldName] = {
          selected: true,
          suggestion: firstSuggestion,
          selectedValue: firstSuggestion.suggested_value,
          selectedId: `${fieldName}-${firstSuggestion.profile_field_name}`
        };
      }
    });

    setSelectedFields(initialSelections);
  }, [suggestions, setSelectedFields]);

  const handleToggleField = (fieldName) => {
    setSelectedFields(prev => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        selected: !prev[fieldName].selected
      }
    }));
  };

  const handleSelectValue = (fieldName, suggestion) => {
    setSelectedFields(prev => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        suggestion,
        selectedValue: suggestion.suggested_value,
        selectedId: `${fieldName}-${suggestion.profile_field_name}` // Add unique identifier
      }
    }));
  };

  // Group suggestions by field
  const groupedSuggestions = suggestions.reduce((acc, suggestion) => {
    if (!acc[suggestion.task_field_name]) {
      acc[suggestion.task_field_name] = [];
    }
    acc[suggestion.task_field_name].push(suggestion);
    return acc;
  }, {});

  return (
    <div className={styles.modalBackdrop}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <div className={styles.headerContent}>
            <h2 className={styles.modalTitle}>{t('autofill.confirmTitle')}</h2>
            <p className={styles.confirmDescription}>
              {t('autofill.confirmDescription')}
            </p>
          </div>
          <button className={styles.closeButton} onClick={onCancel}>&times;</button>
        </div>
        
        <div className={styles.modalInstructions}>
          <div className={styles.instructionItem}>
            <FaInfoCircle className={styles.instructionIcon} />
            <span>{t('autofill.instructions.toggleField')}</span>
          </div>
          <div className={styles.instructionItem}>
            <FaInfoCircle className={styles.instructionIcon} />
            <span>{t('autofill.instructions.selectMatch')}</span>
          </div>
        </div>

        <div className={styles.modalBody}>
          {Object.entries(groupedSuggestions).map(([fieldName, fieldSuggestions]) => {
            const selectedId = selectedFields[fieldName]?.selectedId;
            const hasMultipleMatches = fieldSuggestions.length > 1;
            const sortedSuggestions = [...fieldSuggestions].sort((a, b) => {
              const confidenceOrder = { high: 3, medium: 2, low: 1 };
              return confidenceOrder[b.confidence] - confidenceOrder[a.confidence];
            });

            return (
              <div key={fieldName} className={styles.fieldGroup}>
                <div className={styles.fieldHeader}>
                  <div className={styles.checkboxWrapper}>
                    <label className={styles.checkboxContainer}>
                      <input
                        type="checkbox"
                        checked={selectedFields[fieldName]?.selected ?? false}
                        onChange={() => handleToggleField(fieldName)}
                        id={`check-${fieldName}`}
                        className={styles.hiddenCheckbox}
                      />
                      <span className={styles.checkmark}></span>
                      <span className={styles.fieldName}>{fieldName}</span>
                    </label>
                    {hasMultipleMatches && (
                      <span className={styles.multipleMatchesBadge}>
                        {fieldSuggestions.length} {t('autofill.multipleMatches')}
                      </span>
                    )}
                  </div>
                </div>
                
                <div className={`${styles.suggestionsList} ${selectedFields[fieldName]?.selected ? styles.active : styles.inactive}`}>
                  {sortedSuggestions.map((suggestion, idx) => {
                    const suggestionId = `${fieldName}-${suggestion.profile_field_name}`;
                    const isSelected = selectedId === suggestionId;
                    
                    return (
                      <div 
                        key={`${fieldName}-${idx}`} 
                        className={`${styles.suggestionItem} ${isSelected ? styles.selected : ''}`}
                      >
                        <input
                          type="radio"
                          name={`suggestion-${fieldName}`}
                          id={`suggestion-${fieldName}-${idx}`}
                          checked={isSelected}
                          onChange={() => handleSelectValue(fieldName, suggestion)}
                          className={styles.hiddenRadio}
                          disabled={!selectedFields[fieldName]?.selected}
                        />
                        <label 
                          htmlFor={`suggestion-${fieldName}-${idx}`}
                          className={styles.suggestionLabel}
                        >
                          <div className={styles.suggestionHeader}>
                            <span className={styles.value}>{suggestion.suggested_value}</span>
                            <span className={`${styles.confidence} ${styles[suggestion.confidence]}`}>
                              {suggestion.confidence}
                            </span>
                          </div>
                          <div className={styles.suggestionDetails}>
                            <span className={styles.source}>
                              {t('autofill.fromField')}: {suggestion.profile_field_name}
                            </span>
                            <span className={styles.reasoning}>{suggestion.reasoning}</span>
                          </div>
                          {isSelected && <FaCheckCircle className={styles.selectedIcon} />}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        
        <div className={styles.modalFooter}>
          <button className={styles.confirmButton} onClick={onConfirm}>
            {t('autofill.applySelected')} ({Object.values(selectedFields).filter(f => f.selected).length})
          </button>
          <button className={styles.cancelButton} onClick={onCancel}>
            {t('common.cancel')}
          </button>
        </div>
      </div>
    </div>
  );
};
