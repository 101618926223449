// src/pages/AdminWorkflowList.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AdminWorkflowCard from '../../components/workflow/AdminWorkflowCard';
import config from '../../config';
import { FaPlus } from 'react-icons/fa';

import Styles from '../../styles/pages/AdminDashboard/AdminWorkflowList.module.css';

import DashboardTitle from '../../components/common/DashboardTitle';
import NavTabs from '../../components/common/NavTabs';
import { useTranslation } from 'react-i18next';
import VersionedWorkflowCard from '../../components/workflow/VersionedWorkflowCard';

// Import our new modal
import CreateWorkflowModal from '../../components/Modals/CreateWorkflowModal';

function AdminWorkflowList() {
  // 1) Initialize translation
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  const [workflows, setWorkflows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState('Manage');

  const navigate = useNavigate();

  // For showing/hiding the modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchWorkflows = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/workflows/`, {
          headers: { Authorization: `Token ${localStorage.getItem('authToken')}` },
        });
        setWorkflows(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching workflows:', error);
        setLoading(false);
      }
    };
    fetchWorkflows();
  }, []);

  // Modified delete handler to handle versioned workflows
  const handleDelete = (workflowId) => {
    if (window.confirm(t('adminWorkflowList.confirmDelete'))) {
      axios
        .delete(`${config.apiUrl}/workflows/${workflowId}/`, {
          headers: { Authorization: `Token ${localStorage.getItem('authToken')}` },
        })
        .then(() => {
          // Remove the workflow and any related versions
          setWorkflows(prev => 
            prev.filter(wf => 
              wf.id !== workflowId && 
              wf.parent_workflow !== workflowId
            )
          );
        })
        .catch((error) => {
          console.error('Failed to delete the workflow:', error);
        });
    }
  };

  const groupWorkflows = (workflows) => {
    console.log('Grouping workflows:', workflows);
    const grouped = {};
    
    workflows.forEach(workflow => {
      if (workflow.parent_workflow) {
        // This is a child version
        const baseId = workflow.parent_workflow;
        if (!grouped[baseId]) {
          // Find the parent workflow from the workflows array
          const parentWorkflow = workflows.find(w => w.id === baseId);
          grouped[baseId] = {
            versions: parentWorkflow ? [parentWorkflow, workflow] : [workflow],
            latest: workflow.is_latest ? workflow : null,
            baseWorkflow: parentWorkflow
          };
        } else {
          if (!grouped[baseId].versions.some(v => v.id === workflow.id)) {
            grouped[baseId].versions.push(workflow);
          }
          if (workflow.is_latest) {
            grouped[baseId].latest = workflow;
          }
        }
      } else {
        // This is a parent/standalone workflow
        if (!grouped[workflow.id]) {
          grouped[workflow.id] = {
            versions: [workflow],
            latest: workflow,
            baseWorkflow: workflow
          };
        } else if (!grouped[workflow.id].baseWorkflow) {
          // If we already have versions but no base workflow, this is the parent
          grouped[workflow.id].versions.unshift(workflow);
          grouped[workflow.id].baseWorkflow = workflow;
        }
      }
    });

    // Final processing of groups
    Object.values(grouped).forEach(group => {
      // Sort versions by version number
      group.versions.sort((a, b) => {
        // Ensure parent workflow is first (version 1)
        if (a.id === group.baseWorkflow?.id) return -1;
        if (b.id === group.baseWorkflow?.id) return 1;
        return (b.version_number || 0) - (a.version_number || 0);
      });

      // Set version numbers if not set
      group.versions.forEach((version, index) => {
        if (!version.version_number) {
          version.version_number = index + 1;
        }
      });

      // Ensure we have a latest version
      if (!group.latest) {
        group.latest = group.versions[0];
      }
    });

    console.log('Grouped result:', grouped);
    return grouped;
  };

  // Filter and group workflows
  const getFilteredAndGroupedWorkflows = () => {
    const filtered = workflows.filter(workflow =>
      workflow.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    
    return groupWorkflows(filtered);
  };

  const renderWorkflows = () => {
    if (loading) {
      return (
        <div className={Styles.loadingSection}>
          <div className={Styles.loadingSpinner}></div>
          <div className={Styles.loadingMessage}>{t('adminWorkflowList.loading')}</div>
        </div>
      );
    }

    const groupedWorkflows = getFilteredAndGroupedWorkflows();
    console.log('Grouped workflows for rendering:', groupedWorkflows); // Debug log
    
    return Object.entries(groupedWorkflows).map(([baseId, group]) => {
        // Consider it a versioned workflow if it has multiple versions
        // OR if it has version_number > 1
        const isVersioned = group.versions.length > 1 || 
                          group.versions.some(w => w.version_number > 1);
        
        if (isVersioned) {
            return (
                <VersionedWorkflowCard 
                    key={baseId}
                    workflows={group.versions}
                    latestVersion={group.latest}
                    onDelete={handleDelete}
                />
            );
        }
        
        return (
            <AdminWorkflowCard
                key={baseId}
                workflow={group.versions[0]}
                onDelete={handleDelete}
            />
        );
    });
  };

  // Open the modal
  const handleCreateWorkflow = () => {
    setIsModalOpen(true);
  };

  // Handle "Import Template" -> duplicate workflow
  const handleImportTemplate = async (templateId) => {
    try {
      // Example POST to /workflows/:templateId/import/ that returns newWorkflowId
      const res = await axios.post(
        `${config.apiUrl}/workflows/${templateId}/import/`,
        {},
        { headers: { Authorization: `Token ${localStorage.getItem('authToken')}` } }
      );
      const newWorkflowId = res.data.id;
      // Then redirect to edit-workflow page
      navigate(`/admin-dashboard/edit-workflow/${newWorkflowId}`);
    } catch (error) {
      console.error('Error importing template:', error);
      // Handle error / show notification
    }
  };

  // Handle "Create from PDF"
  const handleCreateFromPDF = async (pdfFile) => {
    try {
      const formData = new FormData();
      formData.append('pdf', pdfFile);
      // Suppose /workflows/from-pdf/ returns { id: newWorkflowId }
      const res = await axios.post(
        `${config.apiUrl}/workflows/from-pdf/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Token ${localStorage.getItem('authToken')}`,
          },
        }
      );
      const newWorkflowId = res.data.id;
      navigate(`/admin-dashboard/edit-workflow/${newWorkflowId}`);
    } catch (error) {
      console.error('Error creating workflow from PDF:', error);
      // Handle error
    }
  };

  // 3) Localize the tab label
  const tabs = [
    { label: t('adminWorkflowList.manageTab'), link: '/admin-dashboard/workflows' },
  ];

  return (
    <div className={`${Styles.adminDashboardContainer} ${isArabic ? Styles.rtl : ''}`}>
      <div className={Styles.adminDashboardContent}>
        <div className={Styles.adminDashboardHeader}>
          <DashboardTitle
            title={t('adminWorkflowList.manageWorkflows')}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            searchPlaceholder={t('adminWorkflowList.searchPlaceholder')}
          />
          <NavTabs tabs={tabs} selectedTab={selectedTab} onTabSelect={setSelectedTab} />
        </div>

        <div className={Styles.workflowActions}>
          <button onClick={handleCreateWorkflow} className={Styles.createWorkflowBtn}>
            <FaPlus className={Styles.plusIcon} />
            {t('adminWorkflowList.createNewWorkflow')}
          </button>
        </div>

        <div className={Styles.adminWorkflowGrid}>
          {renderWorkflows()}
        </div>
      </div>

      {/* Our Modal */}
      <CreateWorkflowModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onImportTemplate={handleImportTemplate}
        onCreateFromPDF={handleCreateFromPDF}
        currentUserId={1 /* or your real user ID */}
      />
    </div>
  );
}

export default AdminWorkflowList;
