const config = {
    apiUrl: 'https://workflowproject-e18361979426.herokuapp.com/api',
    //apiUrl : 'http://127.0.0.1:8000/api',
    localApiUrl: 'http://127.0.0.1:8000/api',

    googleClientId: '1024996110804-6jdl0l5o1ea1j56gllcps3t2l2dcircs.apps.googleusercontent.com'
  };


  
  export default config;
