import React, { useState, useEffect, useRef } from 'react';
import styles from '../../styles/components/FieldTypes/RadioButtonField.module.css'; 
import { calculateLabelWidth } from './FieldTypesUtils';

export const RadioButtonFieldConfig = [
  "fieldLabel",
  "required",
  "help_text",
  "choices",
  "default_value_spinner",
];

function RadioButtonField({ field, onConfigChange, isSelected }) {
  const [localConfig, setLocalConfig] = useState(() => ({
    ...field.config,
    choices: Array.isArray(field.config?.choices)
      ? field.config.choices.join('\n') 
      : "Choice 1\nChoice 2", // Fallback to default
  }));

  const editorRef = useRef(null);

  useEffect(() => {
    setLocalConfig({
      ...field.config,
      choices: Array.isArray(field.config?.choices)
        ? field.config.choices.join('\n') 
        : field.config?.choices || "",
    });

    const labelWidth = calculateLabelWidth(field.config.fieldLabel);
    document.documentElement.style.setProperty(
      `--label-length-${field.id}`,
      labelWidth / 8 + 3
    );
    
  }, [field.config]);

  // Handle updates to the field configuration
  const updateFieldConfig = (key, value) => {
    const updatedConfig = { ...localConfig, [key]: value };
    setLocalConfig(updatedConfig);
    console.log('Updated Config:', updatedConfig);
    onConfigChange({
      ...field.config,
      [key]: value, 
    });
  };

  // Add a new choice
  const addChoice = () => {
    const currentChoices = localConfig.choices.split('\n'); // Split by \n
    const newChoices = [...currentChoices, `Choice ${currentChoices.length + 1}`];
    updateFieldConfig('choices', newChoices.join('\n')); // Join with \n
  };

  // Remove a choice
  const removeChoice = (index) => {
    const currentChoices = localConfig.choices.split('\n'); // Split by \n
    const newChoices = currentChoices.filter((_, i) => i !== index);
    updateFieldConfig('choices', newChoices.join('\n')); // Join with \n
  };

  // Reorder choices
  const reorderChoice = (index, direction) => {
    const currentChoices = localConfig.choices.split('\n'); // Split by \n
    const newChoices = [...currentChoices];
    const [movedChoice] = newChoices.splice(index, 1);
    newChoices.splice(index + direction, 0, movedChoice);
    updateFieldConfig('choices', newChoices.join('\n')); // Join with \n
  };

  return (
    <div className={styles.radioButtonField}>
      {/* Editable Label */}
      <textarea
        className={styles.fieldLabel}
        value={localConfig.fieldLabel || ""}
        placeholder={isSelected ? "Enter field label" : ""}
        onChange={(e) => updateFieldConfig('fieldLabel', e.target.value)}
      />

      {localConfig.required && (
            <span
              style={{
                left: `max(min(calc(-30px + 1ch * var(--label-length-${field.id})), calc(100% - 40px)), calc(18%))`,
                top: '13px',
                position: 'absolute',
                color: 'red',
              }}
            >
              *
            </span>
          )}    

      {/* Radio Buttons Preview */}
      <div className={styles.radioGroup}>
        {localConfig.choices.split('\n').map((choice, index) => (
          <label key={index} className={styles.radioOption}>
            <input
              type="radio"
              name={`radio-${field.id}`}
              value={choice}
              checked={localConfig.default_value_spinner === choice}
              onChange={() => updateFieldConfig('default_value_spinner', choice)}
            />
            {choice}
          </label>
        ))}
      </div>

      {/* Editable Help Text */}
      <textarea
        className={styles.helpText}
        value={localConfig.help_text || ""}
        placeholder={isSelected ? "Enter help text" : ""}
        onChange={(e) => updateFieldConfig('help_text', e.target.value)}
      />

      {/* Grey Line Separator */}
      {isSelected && <hr className={styles.separator} />}

      {/* Choices Editor */}
      {isSelected && (
        <div ref={editorRef} className={styles.choicesEditorSection}>
          <button className={styles.addButton} onClick={addChoice}>Add Choice</button>
          <ul className={styles.choicesList}>
            {localConfig.choices.split('\n').map((choice, index) => (
              <li key={index} className={styles.choiceItem}>
                <input
                  type="text"
                  className={styles.choiceInput}
                  value={choice}
                  onChange={(e) => {
                    const currentChoices = localConfig.choices.split('\n');
                    currentChoices[index] = e.target.value;
                    updateFieldConfig('choices', currentChoices.join('\n'));
                  }}
                />
                <button
                  className={styles.moveButton}
                  onClick={() => reorderChoice(index, -1)}
                  disabled={index === 0}
                >
                  ↑
                </button>
                <button
                  className={styles.moveButton}
                  onClick={() => reorderChoice(index, 1)}
                  disabled={index === localConfig.choices.split('\n').length - 1}
                >
                  ↓
                </button>
                <button className={styles.deleteButton}
                 onClick={() => removeChoice(index)}>Delete
                 </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default RadioButtonField;
