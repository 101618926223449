import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import config from '../../config';

// Icons
import { FaSearch, FaFilter, FaChevronRight, FaArrowLeft } from 'react-icons/fa';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

// Styles
import styles from '../../styles/components/Modals/CreateWorkflowModal.module.css';
import { toast } from 'react-toastify';

export default function CreateWorkflowModal({
  isOpen,
  onClose,
  primaryColor = '#6d00cc',
}) {
  const navigate = useNavigate();

  // Steps: "main", "pdf", "template", "preview"
  const [step, setStep] = useState('main');

  // PDF
  const [pdfFile, setPdfFile] = useState(null);
  const [isDragActive, setIsDragActive] = useState(false);

  // Template data
  const [templates, setTemplates] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isFetchingTemplates, setIsFetchingTemplates] = useState(false);

  // For preview tasks (accordion)
  const [openTaskPanels, setOpenTaskPanels] = useState({});

  // NEW: loading state for “Create from PDF” or “Import Template”
  const [isActionLoading, setIsActionLoading] = useState(false);

  // ─────────────────────────────────────────────────────────
  // RESET WHEN MODAL CLOSES
  // ─────────────────────────────────────────────────────────
  useEffect(() => {
    if (!isOpen) {
      setStep('main');
      setPdfFile(null);
      setIsDragActive(false);
      setTemplates([]);
      setCategories([]);
      setSelectedCategory('All');
      setSearchQuery('');
      setSelectedTemplate(null);
      setIsFetchingTemplates(false);
      setOpenTaskPanels({});
      setIsActionLoading(false);
    }
  }, [isOpen]);

  // Decide container size for the modal
  const containerClass =
    step === 'main' || step === 'pdf'
      ? `${styles.modalContainerSmall}`
      : `${styles.modalContainerLarge}`;

  // ─────────────────────────────────────────────────────────
  // MAIN STEP ICONS
  // ─────────────────────────────────────────────────────────
  const iconPlus = (
    <svg
      className={styles.cardSvg}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      style={{ color: primaryColor }}
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
    </svg>
  );
  const iconTemplate = (
    <svg
      className={styles.cardSvg}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      style={{ color: primaryColor }}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M4 5a1 1 0 011-1h14a1 1 0
           011 1v2a1 1 0 01-1 1H5a1 1 0
           01-1-1V5zM4 13a1 1 0 011-1h6a1
           1 0 011 1v6a1 1 0 01-1 1H5a1
           1 0 01-1-1v-6zM16 13a1 1 0
           011-1h2a1 1 0 011 1v6a1 1 0
           01-1 1h-2a1 1 0 01-1-1v-6z"
      />
    </svg>
  );
  const iconPDF = (
    <AiOutlineFilePdf size={48} color={primaryColor} style={{ marginBottom: '0.5rem' }} />
  );

  // ─────────────────────────────────────────────────────────
  // MAIN STEP
  // ─────────────────────────────────────────────────────────
  const renderMainStep = () => {
    const options = [
      {
        title: 'Create From Scratch',
        description: 'Begin with a blank workflow',
        icon: iconPlus,
        onClick: () => navigate('/admin-dashboard/create-workflow'),
      },
      {
        title: 'Choose a Template',
        description: 'Browse public and personal workflows',
        icon: iconTemplate,
        onClick: () => {
          setStep('template');
          fetchTemplates(); // load from backend
        },
      },
      {
        title: 'Create From PDF',
        description: 'Upload a PDF and convert it to a workflow',
        icon: iconPDF,
        onClick: () => setStep('pdf'),
      },
    ];

    return (
      <>
        <h2 className={styles.modalTitle}>Create a New Workflow</h2>
        <div className={styles.tipBox}>
          <strong>Did you know?</strong> You can use our <span style={{ color: primaryColor }}>AI assistant</span>{' '}
          at any time to help brainstorm or refine your workflow.
        </div>

        <div className={styles.cardsRow}>
          {options.map((opt, index) => (
            <motion.div
              key={opt.title}
              className={styles.card}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              onClick={opt.onClick}
            >
              {opt.icon}
              <div className={styles.cardTitle}>{opt.title}</div>
              <div className={styles.cardDesc}>{opt.description}</div>
            </motion.div>
          ))}
        </div>
      </>
    );
  };

  // ─────────────────────────────────────────────────────────
  // FETCH TEMPLATES (GET /workflows/)
  // ─────────────────────────────────────────────────────────
  const fetchTemplates = async () => {
    try {
      setIsFetchingTemplates(true);
      const token = localStorage.getItem('authToken');
      const response = await axios.get(`${config.apiUrl}/workflows/`, {
        headers: { Authorization: `Token ${token}` },
      });
      const data = response.data || [];

      // Gather categories
      const catSet = new Set(['All']);
      data.forEach((item) => {
        if (item.category) catSet.add(item.category);
      });

      setCategories([...catSet]);
      setTemplates(data);
    } catch (error) {
      console.error('Error fetching templates:', error);
    } finally {
      setIsFetchingTemplates(false);
    }
  };

  // ─────────────────────────────────────────────────────────
  // PDF STEP
  // ─────────────────────────────────────────────────────────
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragActive(true);
  };
  const handleDragLeave = () => {
    setIsDragActive(false);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setPdfFile(e.dataTransfer.files[0]);
    }
  };
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setPdfFile(e.target.files[0]);
    }
  };

  const renderPDFStep = () => {
    const isEnabled = !!pdfFile;

    return (
      <>
        <h2 className={styles.modalTitle}>Create From PDF</h2>
        <div className={styles.tipBox}>
          Our <span style={{ color: primaryColor }}>AI assistant</span> can also help refine the workflow
          generated from your PDF.
        </div>

        <div className={styles.pdfContainer}>
          {/* Drop zone */}
          {!pdfFile && (
            <div
              className={`${styles.pdfDropZone} ${isDragActive ? styles.pdfDropZoneActive : ''}`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              onClick={() => document.getElementById('pdfInput').click()}
            >
              <AiOutlineFilePdf className={styles.pdfIcon} />
              <p className={styles.pdfDropText}>
                Drag &amp; drop your <strong>PDF file</strong> here or{' '}
                <u style={{ color: primaryColor }}>click to browse</u>.
              </p>
              <p style={{ fontSize: '0.8rem', color: '#666' }}>
                Note: The PDF should contain less than 1000 words.
              </p>
            </div>
          )}

          {/* If a file is uploaded, show it */}
          {pdfFile && (
            <div className={styles.pdfFileBox}>
              <AiOutlineFilePdf
                className={styles.pdfIcon}
                style={{ margin: 0, fontSize: '2rem', color: primaryColor }}
              />
              <span className={styles.uploadedFileName}>{pdfFile.name}</span>
              <button
                type="button"
                className={styles.changeFileBtn}
                onClick={() => {
                  setPdfFile(null);
                  setIsDragActive(false);
                }}
              >
                Change File
              </button>
            </div>
          )}

          <input
            id="pdfInput"
            type="file"
            accept="application/pdf"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />

          {/* Create Workflow button */}
          <div className={styles.pdfButtons}>
            <motion.button
              className={
                isEnabled
                  ? `${styles.createPdfBtn} ${styles.createPdfBtnActive}`
                  : styles.createPdfBtn
              }
              whileHover={isEnabled ? { scale: 1.02 } : {}}
              whileTap={isEnabled ? { scale: 0.98 } : {}}
              onClick={() => {
                if (isEnabled) {
                  handleCreateFromPDF(pdfFile);
                }
              }}
            >
              Create Workflow
            </motion.button>
          </div>
        </div>
      </>
    );
  };

  // Actually call your backend endpoint for PDF -> new workflow
  const handleCreateFromPDF = async (file) => {
    try {
      setIsActionLoading(true); // show loading overlay
      const token = localStorage.getItem('authToken');
      const formData = new FormData();
      formData.append('pdf', file);

      // Suppose your backend has an endpoint: POST /workflows/from-pdf/
      const res = await axios.post(
        `${config.apiUrl}/pdf-to-workflow/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Token ${token}`,
          },
        }
      );

      console.log('Generated workflow:', res.data.sucess);

      const generatedWorkflow = res.data;
      navigate('/admin-dashboard/create-workflow', {
        state: { generatedWorkflow },
      });

    } catch (error) {
      console.error('Error creating workflow from PDF:', error);
      toast.error('Error creating workflow from PDF: ' + error.response?.data?.error);
    } finally {
      setIsActionLoading(false); // hide loading overlay
    }
  };

  // ─────────────────────────────────────────────────────────
  // TEMPLATE STEP
  // ─────────────────────────────────────────────────────────
  const handleCategorySelect = (cat) => setSelectedCategory(cat);

  const filteredTemplates = templates.filter((t) => {
    const inCat =
      selectedCategory === 'All' ||
      (t.category && t.category.toLowerCase() === selectedCategory.toLowerCase());
    const inSearch = t.name?.toLowerCase().includes(searchQuery.toLowerCase());
    return inCat && inSearch;
  });

  const renderTemplateStep = () => {
    if (isFetchingTemplates) {
      return (
        <div className={styles.loadingSection}>
          <div className={styles.loadingSpinner}></div>
          <p>Loading templates...</p>
        </div>
      );
    }

    return (
      <>
        <h2 className={styles.modalTitle}>Choose a Template</h2>
        <div className={styles.tipBox}>
          <strong>Tip:</strong> You can use our <span style={{ color: primaryColor }}>AI assistant</span> to modify
          the chosen template before finalizing.
        </div>

        <div className={styles.templateViewContainer}>
          {/* Left sidebar: categories */}
          <div className={styles.sidebarContainer}>
            <div className={styles.sidebarTitle}>Categories</div>
            {categories.map((cat) => (
              <button
                key={cat}
                className={`${styles.categoryButton} ${
                  selectedCategory === cat ? styles.categoryActive : ''
                }`}
                onClick={() => handleCategorySelect(cat)}
              >
                {cat}
                <FaChevronRight size={14} />
              </button>
            ))}
          </div>

          {/* Right side: search + grid */}
          <div className={styles.templateRightSide}>
            <div className={styles.searchBar}>
              <div style={{ position: 'relative', flex: 1 }}>
                <FaSearch
                  size={14}
                  style={{
                    position: 'absolute',
                    left: '8px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    color: '#aaa',
                  }}
                />
                <input
                  className={styles.searchInput}
                  placeholder="Search templates..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{ paddingLeft: '28px' }}
                />
              </div>
              <button className={styles.filterBtn}>
                <FaFilter size={14} />
              </button>
            </div>

            <div className={styles.templatesGrid}>
              {filteredTemplates.length > 0 ? (
                filteredTemplates.map((temp, index) => (
                  <motion.div
                    key={temp.id}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.05 }}
                  >
                    <div
                      className={styles.templateCard}
                      onClick={() => {
                        setSelectedTemplate(temp);
                        setStep('preview');
                      }}
                    >
                      <div className={styles.templateCardImg}>
                        {temp.image_url ? (
                          <img
                            src={temp.image_url}
                            alt={temp.name}
                            style={{ width: '100%', height: '100%' }}
                          />
                        ) : (
                          <div className={styles.noImage}></div>
                        )}
                      </div>
                      <div className={styles.templateCardContent}>
                        <div className={styles.templateCardTitle}>{temp.name}</div>
                        <div className={styles.templateCardCategory}>{temp.category}</div>
                      </div>
                    </div>
                  </motion.div>
                ))
              ) : (
                <div className={styles.noTemplatesMessage}>
                  No templates found. Try a different category or search term.
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  // ─────────────────────────────────────────────────────────
  // PREVIEW STEP (with "Import" using the new backend API)
  // ─────────────────────────────────────────────────────────
  const renderPreviewStep = () => {
    const template = selectedTemplate || {};
    const tasks = template.tasks || [];

    return (
      <>
        <h2 className={styles.previewTitle}>{template.name}</h2>
        {template.description && (
          <div className={styles.workflowDescription}>
            <h3>Workflow Description</h3>
            <p>{template.description}</p>
          </div>
        )}

        <div className={styles.tasksSection}>
          <h3>Tasks</h3>
          {tasks.length > 0 ? (
            tasks.map((task) => {
              const isOpen = openTaskPanels[task.id] || false;
              return (
                <div key={task.id} className={styles.taskPanel}>
                  <div
                    className={styles.taskPanelHeader}
                    onClick={() => handleToggleTaskPanel(task.id)}
                  >
                    <span>{task.name}</span>
                    {isOpen ? <FaChevronUp /> : <FaChevronDown />}
                  </div>
                  {isOpen && (
                    <div className={styles.taskPanelBody}>
                      {task.description && (
                        <p className={styles.taskDescription}>{task.description}</p>
                      )}
                      {task.task_fields?.length > 0 && (
                        <div className={styles.taskFields}>
                          <p><strong>Task Fields:</strong></p>
                          <ul>
                            {task.task_fields.map((field, idx) => (
                              <li key={idx} className={styles.fieldItem}>
                                <span className={styles.fieldName}>{field.fieldName}</span>{' '}
                                <span className={styles.fieldType}>({field.fieldTypeID})</span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <p>No tasks found in this template.</p>
          )}
        </div>

        <button
          className={styles.useTemplateBtn}
          onClick={() => handleImportTemplate(template.id)}
        >
          Import This Template
        </button>
      </>
    );
  };

  // Toggle open/close for tasks in the preview
  const handleToggleTaskPanel = (taskId) => {
    setOpenTaskPanels((prev) => ({
      ...prev,
      [taskId]: !prev[taskId],
    }));
  };

  // ─────────────────────────────────────────────────────────
  // Import Template logic (POST /workflows/<templateId>/import/)
  // ─────────────────────────────────────────────────────────
  const handleImportTemplate = async (templateId) => {
    try {
      setIsActionLoading(true); // show loading overlay
      const token = localStorage.getItem('authToken');
      const res = await axios.post(
        `${config.apiUrl}/workflows/${templateId}/import/`,
        {},
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      // Suppose the response is { new_workflow_id: 999, message: "..."}
      const newWorkflowId = res.data.new_workflow_id;
      // Now navigate to edit that new workflow
      navigate(`/admin-dashboard/edit-workflow/workflows/${newWorkflowId}`);
    } catch (error) {
      console.error('Error importing template:', error);
    } finally {
      setIsActionLoading(false); // hide loading overlay
    }
  };

  // ─────────────────────────────────────────────────────────
  // FINAL RENDER
  // ─────────────────────────────────────────────────────────
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={containerClass}>
        {/* Close Button */}
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>

        {/* Show spinner overlay if isActionLoading */}
        {isActionLoading && (
          <div className={styles.actionLoadingOverlay}>
            <div className={styles.actionLoadingSpinner}></div>
            <p className={styles.actionLoadingText}>Processing...</p>
          </div>
        )}

        {step === 'main' && renderMainStep()}
        {step === 'pdf' && renderPDFStep()}
        {step === 'template' && renderTemplateStep()}
        {step === 'preview' && renderPreviewStep()}

        {/* Back Button */}
        {step !== 'main' && (
          <button
            className={styles.backBtn}
            onClick={() => {
              if (step === 'preview') {
                setStep('template');
              } else {
                setStep('main');
              }
            }}
          >
            <FaArrowLeft size={16} />
            Back
          </button>
        )}
      </div>
    </div>
  );
}
