import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';

import styles from '../../styles/pages/UserDashboard/WorkflowStepDetail.module.css';
import { MultiStepForm } from '../../components/WorkflowDetails/MultiStepForm';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { AutofillConfirmModal } from '../../components/Modals/AutofillConfirmModal';

function WorkflowStepDetail() {
  const { t } = useTranslation();

  const { id, stepId } = useParams();
  const navigate = useNavigate();

  const [task, setTask] = useState(null);
  const [userTask, setUserTask] = useState(null);
  const [fieldsCompleted, setFieldsCompleted] = useState(0);
  const [totalFields, setTotalFields] = useState(0);
  const [workflowTasks, setWorkflowTasks] = useState([]);

  const [taskDataLoaded, setTaskDataLoaded] = useState(false);
  const [workflowTasksLoaded, setWorkflowTasksLoaded] = useState(false);

  // Reference to call validation in MultiStepForm
  const multiStepFormRef = useRef(null);

  const [conflicts, setConflicts] = useState([]);
  const [showConflictsModal, setShowConflictsModal] = useState(false);
  // We'll store the user's choices (update/keep/new) for each conflict here.
  // Keyed by the conflict's field_name, for example.
  const [conflictResolutions, setConflictResolutions] = useState({});
  const [showProfileConfirmModal, setShowProfileConfirmModal] = useState(false);
  const [pendingFieldsData, setPendingFieldsData] = useState([]);
  const [workflowName, setWorkflowName] = useState('');
  const [workflowDescription, setWorkflowDescription] = useState('');

  const [autofillSuggestions, setAutofillSuggestions] = useState([]);
  const [loadingAutofill, setLoadingAutofill] = useState(false);
  const [showAutofillBar, setShowAutofillBar] = useState(true);
  const [showAutofillConfirmModal, setShowAutofillConfirmModal] = useState(false);
  const [selectedAutofillFields, setSelectedAutofillFields] = useState({});

  useEffect(() => {
    setTask(null);
    setUserTask(null);
    setFieldsCompleted(0);
    setTotalFields(0);
    setWorkflowTasks([]);
    setTaskDataLoaded(false);
    setWorkflowTasksLoaded(false);
  }, [id, stepId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = localStorage.getItem('authToken');
        const response = await axios.get(`${config.apiUrl}/user-workflows/${id}/`, {
          headers: { 'Authorization': `Token ${authToken}` }
        });

        console.log('Fetched workflow details:', response.data);

        const fetchedWorkflowTasks = response.data.workflow.tasks;
        setWorkflowName(response.data.workflow.name);
        setWorkflowDescription(response.data.workflow.description);
        setWorkflowTasks(fetchedWorkflowTasks);

        const taskDetail = fetchedWorkflowTasks.find(t => `${t.id}` === stepId);
        if (!taskDetail) {
          navigate(`/user-dashboard/workflows/${id}`);
          return;
        }
        setTask(taskDetail);

        const uTask = response.data.user_tasks.find(t => `${t.task}` === stepId);
        if (!uTask) {
          navigate(`/user-dashboard/workflows/${id}`);
          return;
        }
        setUserTask(uTask);

        if (uTask.user_task_fields) {
          setTotalFields(uTask.user_task_fields.length);
          const filledCount = uTask.user_task_fields.filter(f => f.fieldValue).length;
          setFieldsCompleted(filledCount);
        }

        setTaskDataLoaded(true);
        setWorkflowTasksLoaded(true);
      } catch (error) {
        console.error('Failed to fetch task details:', error);
        navigate(`/user-dashboard/workflows/${id}`);
      }
    };

    fetchData();
  }, [id, stepId, navigate]);

  useEffect(() => {
    const fetchAutofillSuggestions = async () => {
      if (!task || !taskDataLoaded) return;
      
      try {
        setLoadingAutofill(true);
        const authToken = localStorage.getItem('authToken');
        
        const taskFields = task.task_fields.map(field => ({
          name: field.fieldName,
          type: field.fieldTypeID,
          config: field.config
        }));

        const aiResponse = await axios.post(
          `${config.apiUrl}/ai/suggest-autofill/`,
          {
            task_fields: taskFields,
            workflow_context: {
              name: workflowName,
              description: workflowDescription
            }
          },
          { 
            headers: { 
              'Authorization': `Token ${authToken}`,
              'Content-Type': 'application/json'
            }
          }
        );

        // Handle both success and error cases
        if (aiResponse.data.error) {
          console.warn('Autofill warning:', aiResponse.data.error);
          setAutofillSuggestions([]);
        } else {
          const suggestions = aiResponse.data.response.suggestions || [];
          setAutofillSuggestions(suggestions);
        }

      } catch (error) {
        console.error('Failed to get autofill suggestions:', error);
        setAutofillSuggestions([]);
      } finally {
        setLoadingAutofill(false);
      }
    };

    fetchAutofillSuggestions();
  }, [task, taskDataLoaded, workflowName, workflowDescription]);

  const handlePageUpdate = (step, data) => {
    let filledCount = 0;
    Object.values(data).forEach(value => {
      if (value && value.trim() !== '') filledCount++;
    });
    setFieldsCompleted(filledCount);
  };

  const allDataLoaded = taskDataLoaded && workflowTasksLoaded;
  const currentIndex = (allDataLoaded && workflowTasks.length > 0 && task)
    ? workflowTasks.findIndex(t => t.id === task.id)
    : null;

  if (allDataLoaded && (currentIndex === -1 || currentIndex === null)) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.loadingText}>{t('workflowStepDetail.invalidTaskData')}</div>
      </div>
    );
  }

  const isFirstStep = allDataLoaded && currentIndex === 0;
  const isLastStep = allDataLoaded && currentIndex === workflowTasks.length - 1;

  const handleCircleClick = (taskIndex) => {
    const taskId = workflowTasks[taskIndex].id;
    navigate(`/user-dashboard/workflows/${id}/step/${taskId}`);
  };

  const handlePrevious = () => {
    if (!isFirstStep) {
      const prevTaskId = workflowTasks[currentIndex - 1].id;
      navigate(`/user-dashboard/workflows/${id}/step/${prevTaskId}`);
    }
  };

  const handleNext = () => {
    if (!isLastStep) {
      const nextTaskId = workflowTasks[currentIndex + 1].id;
      navigate(`/user-dashboard/workflows/${id}/step/${nextTaskId}`);
    }
  };

  const handleSubmit = () => {
    navigate(`/user-dashboard/workflows/${id}`);
  };

  const handleTaskSubmit = async () => {
    if (!multiStepFormRef.current) return;

    // Validate
    const isValid = multiStepFormRef.current.validateFields();
    if (!isValid) {
      toast.error('Some fields are invalid.');
      return;
    }

    const answers = multiStepFormRef.current.answers;
    const fieldsType = multiStepFormRef.current.fieldsType;
    // Transform
    const fieldsData = Object.keys(answers).map(key => ({
      field_name: key,
      field_value: answers[key],
      field_type: fieldsType[key]
    }));
    toast.success('Task submitted successfully!');
    setPendingFieldsData(fieldsData);
    setShowProfileConfirmModal(true);
  };

  const handleResolveConflicts = async () => {
    // build the next request body
    const fieldsWithActions = conflicts.map(conf => {
      const chosenAction = conflictResolutions[conf.field_name.toLowerCase()] || 'keep';
      return {
        field_name: conf.field_name,
        field_value: conf.field_value,
        action: chosenAction
      };
    });

    try {
      const authToken = localStorage.getItem('authToken');
      const response = await fetch(`${config.apiUrl}/user_info/sections-fields/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${authToken}`
        },
        body: JSON.stringify(fieldsWithActions),
      });

      if (!response.ok) {
        throw new Error(`Failed to resolve conflicts: ${response.status}`);
      }

      const respData = await response.json();
      console.log('Conflicts resolved:', respData);

      toast.success('Conflicts resolved!');
      setShowConflictsModal(false);
      setConflicts([]);
      // Optionally refresh or do something
    } catch (error) {
      console.error(error);
      toast.error('Error resolving conflicts.');
    }
  };

  const handleConfirmProfileUpdate = async () => {
    try {
      if (!pendingFieldsData || pendingFieldsData.length === 0) {
        toast.error("No data to update.");
        setShowProfileConfirmModal(false);
        return;
      }

      const dataResponse = {
        workflow_name: workflowName,
        workflow_description: workflowDescription,
        fields: pendingFieldsData
      };

      // Example: We do the same logic you had in the original handleTaskSubmit
      const authToken = localStorage.getItem('authToken');
      const response = await fetch(`${config.apiUrl}/user_info/sections-fields/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${authToken}`
        },
        body: JSON.stringify(dataResponse),
      });

      if (response.status === 409) {
        // handle conflict as before
        const responseData = await response.json();
        console.log('Conflicts from backend:', responseData);

        if (responseData.conflicts && Array.isArray(responseData.conflicts)) {
          setConflicts(responseData.conflicts);
          const initialResolutions = {};
          responseData.conflicts.forEach(conf => {
            initialResolutions[conf.field_name.toLowerCase()] = null;
          });
          setConflictResolutions(initialResolutions);
          // show the conflict resolution modal
          setShowConflictsModal(true);
        } else {
          toast.error('Conflict detected, but no details provided.');
        }
      }
      else if (!response.ok) {
        throw new Error(`Request failed with status ${response.status}`);
      }
      else {
        // success
        const responseData = await response.json();
        console.log('Success:', responseData);
        toast.success('Profile updated successfully!');
      }
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong while updating profile.');
    } finally {
      // Always hide the modal after we do the request
      setShowProfileConfirmModal(false);
    }
  };

  const handleApplyAutofill = () => {
    // Initialize selected fields with all suggestions set to true
    const initialSelectedFields = {};
    autofillSuggestions.forEach(suggestion => {
      if (suggestion.confidence === 'high' || suggestion.confidence === 'medium') {
        initialSelectedFields[suggestion.task_field_name] = {
          selected: true,
          suggestion
        };
      }
    });
    setSelectedAutofillFields(initialSelectedFields);
    setShowAutofillConfirmModal(true);
  };

  const handleConfirmAutofill = async () => {
    if (!multiStepFormRef.current) return;
    
    const currentAnswers = { ...multiStepFormRef.current.answers };
    const updatedFields = [];
    let updatedCount = 0;
    
    // Collect all fields that need to be updated
    Object.entries(selectedAutofillFields).forEach(([fieldName, data]) => {
      if (data.selected) {
        currentAnswers[fieldName] = data.suggestion.suggested_value;
        const fieldId = userTask.user_task_fields.find(f => f.fieldName === fieldName)?.id;
        if (fieldId) {
          updatedFields.push({
            fieldId,
            value: data.suggestion.suggested_value
          });
        }
        updatedCount++;
      }
    });
  
    if (updatedCount > 0) {
      try {
        // Save all field updates in parallel
        const authToken = localStorage.getItem('authToken');
        await Promise.all(updatedFields.map(field => 
          axios.patch(
            `${config.apiUrl}/user-task-fields/${field.fieldId}/`,
            { fieldValue: field.value },
            { headers: { 'Authorization': `Token ${authToken}` } }
          )
        ));

        // Update form state after successful save
        multiStepFormRef.current.setAnswers(currentAnswers);
        setFieldsCompleted(prev => prev + updatedCount);
        toast.success(t('workflowStepDetail.autofillApplied'));
        setShowAutofillBar(false);
      } catch (error) {
        console.error('Error saving autofill data:', error);
        toast.error('Failed to save some field values. Please try again.');
      }
    }
    setShowAutofillConfirmModal(false);
  };

  const progress = totalFields > 0 ? Math.round((fieldsCompleted / totalFields) * 100) : 0;

  console.log('Task:', task);
  console.log('User task:', userTask);
  console.log('Fields completed:', fieldsCompleted, 'out of', totalFields);

  return (
    <div className={styles.container}>
      <div className={styles.topBar}>
        <button
          className={styles.backLinkButtonTop}
          onClick={() => navigate(`/user-dashboard/workflows/${id}`)}
          aria-label={t('workflowStepDetail.backToWorkflow')}
        >
          ← {t('workflowStepDetail.backToWorkflow')}
        </button>
      </div>

      {workflowTasks.length > 0 && (
        <div className={styles.stepNav}>
          <div className={styles.stepLine}>
            {workflowTasks.map((wt, i) => {
              const isCurrent = allDataLoaded && i === currentIndex;
              const circleClass = isCurrent
                ? styles.stepCircleCurrent
                : styles.stepCircleFuture;
              return (
                <div
                  key={wt.id}
                  className={styles.stepItem}
                  onClick={() => handleCircleClick(i)}
                  title={wt.name}
                  role="button"
                  tabIndex={0}
                  aria-label={`Go to step ${i + 1}: ${wt.name}`}
                  onKeyDown={(e) => { if (e.key === 'Enter') handleCircleClick(i); }}
                >
                  <div className={circleClass}>
                    {i + 1}
                  </div>
                  {i < workflowTasks.length - 1 && <div className={styles.stepConnector} />}
                </div>
              );
            })}
          </div>
        </div>
      )}

      <div className={styles.pageWrapper}>
        <div className={styles.formContainer}>
          {allDataLoaded ? (
            <MultiStepForm
              ref={multiStepFormRef}
              task={task}
              step={parseInt(stepId, 10) - 1}
              onPageUpdate={handlePageUpdate}
              workflowId={id}
              userTask={userTask}
            />
          ) : (
            <div className={styles.transitionInForm}>
              <div className={styles.loader}></div>
              <div className={styles.loadingText}>{t('workflowStepDetail.loadingStep')}</div>
            </div>
          )}
        </div>

        {allDataLoaded && (
          <div className={styles.footerNav}>
            {!isFirstStep ? (
              <button
                className={`${styles.navButton} ${styles.prevButton}`}
                onClick={handlePrevious}
                aria-label={t('workflowStepDetail.previous')}
              >
                ← {t('workflowStepDetail.previous')}
              </button>
            ) : (
              <div></div>
            )}

            {/* Submit button for the current task */}
            <button
              className={`${styles.navButton} ${styles.submitButton}`}
              onClick={handleTaskSubmit}
              aria-label={t('workflowStepDetail.submitTask')}
            >
              {t('workflowStepDetail.submitTask')}
            </button>

            {!isLastStep && (
              <button
                className={`${styles.navButton} ${styles.nextButton}`}
                onClick={handleNext}
                aria-label={t('workflowStepDetail.next')}
              >
                {t('workflowStepDetail.next')} →
              </button>
            )}

            {isLastStep && (
              <button
                className={`${styles.navButton} ${styles.submitButton}`}
                onClick={handleSubmit}
                aria-label={t('workflowStepDetail.submitWorkflow')}
              >
                {t('workflowStepDetail.submitWorkflow')}
              </button>
            )}
          </div>
        )}
      </div>

      {showProfileConfirmModal && (
        <div className={styles.modalBackdrop}>
          <div className={styles.modal}>
            <div className={styles.modalHeader}>
              <h2 className={styles.modalTitle}>Update Profile Confirmation</h2>
              <button
                className={styles.closeButton}
                onClick={() => setShowProfileConfirmModal(false)}
                aria-label="Close modal"
              >
                &times;
              </button>
            </div>
            <div className={styles.modalBody}>
              <p>Do you want to update your profile data with the new data you have just filled out?</p>
            </div>
            <div className={styles.modalFooter}>
              <div className={styles.modalFooterButtons}>
                <button className={styles.actionButton} onClick={handleConfirmProfileUpdate}>
                  Yes
                </button>
                <button className={styles.cancelButton} onClick={() => setShowProfileConfirmModal(false)}>
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/*
         =====================================================================
         The Conflict Resolution Modal (when backend returns 409)
         =====================================================================
      */}
      {showConflictsModal && (
        <div className={styles.modalBackdrop}>
          <div className={styles.modal}>
            <div className={styles.modalHeader}>
              <h2 className={styles.modalTitle}>Resolve Conflicts</h2>
              <button
                className={styles.closeButton}
                onClick={() => setShowConflictsModal(false)}
                aria-label="Close modal"
              >
                &times;
              </button>
            </div>

            {/* Scrollable container */}
            <div className={styles.modalBody}>
              <p className={styles.modalDescription}>
                We found some conflicts with your existing data.
                Please choose how to handle each field below.
              </p>

              {conflicts.map((conf) => {
                const fNameLower = conf.field_name.toLowerCase();
                return (
                  <div key={conf.field_name} className={styles.conflictItem}>
                    <div className={styles.conflictItemHeader}>
                      <strong className={styles.conflictFieldName}>
                        {conf.field_name}
                      </strong>
                    </div>
                    <div className={styles.conflictItemBody}>
                      <p className={styles.conflictMessage}>{conf.message}</p>
                      <div className={styles.conflictAction}>
                        <label
                          htmlFor={`conflict-select-${fNameLower}`}
                          className={styles.conflictLabel}
                        >
                          Action:
                        </label>
                        <select
                          id={`conflict-select-${fNameLower}`}
                          className={styles.conflictSelect}
                          value={conflictResolutions[fNameLower] || ''}
                          onChange={(e) => {
                            setConflictResolutions((prev) => ({
                              ...prev,
                              [fNameLower]: e.target.value
                            }));
                          }}
                        >
                          <option value="">-- Select Action --</option>
                          {conf.options.map(opt => (
                            <option key={opt} value={opt}>{opt}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className={styles.modalFooter}>
              <div className={styles.modalFooterButtons}>
                <button className={styles.actionButton} onClick={handleResolveConflicts}>
                  Submit Resolutions
                </button>
                <button
                  className={styles.cancelButton}
                  onClick={() => setShowConflictsModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showAutofillBar && autofillSuggestions.length > 0 && (
        <div className={styles.autofillBar}>
          <div className={styles.autofillContent}>
            <span>
              {t('workflowStepDetail.autofillAvailable')}
              {loadingAutofill && <div className={styles.smallLoader}></div>}
            </span>
            <div className={styles.autofillActions}>
              <button
                className={styles.autofillButton}
                onClick={handleApplyAutofill}
                disabled={loadingAutofill}
              >
                {t('workflowStepDetail.applyAutofill')}
              </button>
              <button
                className={styles.dismissButton}
                onClick={() => setShowAutofillBar(false)}
                aria-label="Dismiss"
              >
                ×
              </button>
            </div>
          </div>
        </div>
      )}

      {showAutofillConfirmModal && (
        <AutofillConfirmModal
          suggestions={autofillSuggestions}
          selectedFields={selectedAutofillFields}
          setSelectedFields={setSelectedAutofillFields}
          onConfirm={handleConfirmAutofill}
          onCancel={() => setShowAutofillConfirmModal(false)}
        />
      )}
    </div>
  );
}

export default WorkflowStepDetail;
