import React, { useState } from 'react';
import NavbarAddWorkflow from '../../components/Navbars/NavbarAddWorkflow';
import WorkflowBuilder from '../../components/WorkflowBuilder/WorkflowBuilder';
import styles from '../../styles/pages/AdminDashboard/AddWorkflow.module.css';
import AIAssistant from '../../components/WorkflowBuilder/AIAssistant'; 
import axios from 'axios';
import config from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { ReactFlowProvider } from "reactflow";
import { useLocation } from 'react-router-dom';


import WorkflowSettings from '../../components/WorkflowBuilder/WorkflowSettings';
import AdminDependenciesView from '../../components/WorkflowBuilder/AdminDependenciesView';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Toast } from 'react-bootstrap';

const geminiInstruction = `
[Generate a JSON workflow strictly. No extra text, only JSON. The workflow should be rich, containing multiple detailed tasks and multiple fields per task to ensure completeness. 

1. **Interpret User Input Dynamically**:
   - If the input is can be **workflow description** (whether structured or unstructured), **extract** tasks, approvals, and dependencies to build a detailed workflow.
   - If the input is **completely not workflow-related** (e.g., random questions, coding requests), return an error message.

2. **Rich Workflow Generation**:
   - Generate at least **5-10 tasks** with detailed steps.
   - Each task must include **3-5 fields**.
   - Maintain **logical dependencies** between tasks.

3. **Language Adaptation**:
   - Respond in the same user request language. Unless user specifies a language.
   - If the user’s request is in **Arabic**, return the workflow in **Arabic**.
   - If the user’s request is in **English**, return the workflow in **English**.
   - If user typed in multiple languages, **choose the most frequent** language.
   - Make The selected language applied to all fields and config values and tasks.


4. **Validation**:
   - If the request **contains a workflow description**, process it.
   - If the request is **completely unrelated** to workflow creation, return:
     \`\`\`json
     {
         "success": false,
         "summary": "Error: This request is outside the allowed scope of workflow generation."
     }
     \`\`\`
   - If the request is **ambiguous** but could be a workflow, attempt to infer and generate a reasonable workflow.


**Response Format:**
\`\`\`json
{
    "success": true,
    "name": "[Workflow name]",
    "description": "[Max 100 chars]",
    "type": "primitive",
    "summary": "[3-5 sentence summary]",
    "tasks": [
        {
            "name": "[Task name]",
            "description": "[Task description, ensuring clarity and depth]",
            "status": "Pending",
            "task_fields": [
                {
                    "fieldTypeID": "[One of: text|checkbox|spinner|radiobutton|number|date|file_upload|MultiSelect|signature]",
                    "index": [number],
                    "config": { [Must match exactly one of the given field configs without changes] }
                },
                ...
            ]
        },
        ...
    ],
    "dependencies": [
        {
            "from_task": [taskIndex],
            "to_task": [taskIndex],
            "dependency_type": "[One of: must_submit_before_view|can_view_only_before_submit|no_restriction]"
        },
        ...
    ]
}
\`\`\`

**Field Configs (no changes, must include all keys for each field type but can leave some values empty)(the values are examples here):**
- **Text:** \`{"fieldLabel":"Enter your name","required":true,"help_text":"Full name appears on official documents","placeholder":"e.g., John Doe","max_length":100,"default_value":""}\`
- **Checkbox:** \`{"fieldLabel":"I agree to the terms and conditions","required":true,"help_text":"Please confirm your agreement","default_value_checkbox":false}\`
- **Radiobutton:** \`{"fieldLabel":"Select your gender","required":true,"help_text":"Choose one of the choices","choices":"Male\\nFemale","default_value":"Male"}\`
- **Spinner:** \`{"fieldLabel":"Select your country","required":true,"help_text":"Choose the country you reside in","choices":"Palestine\\nJordan\\nEgypt\\nLebanon","default_value":"Palestine"}\`
- **Number:** \`{"fieldLabel":"Enter your age","required":true,"placeholder":"e.g., 18","help_text":"Your current age","min_value":0,"max_value":120,"default_value":18}\`
- **Date:** \`{"fieldLabel":"Select appointment date","required":true,"help_text":"Choose a date for your appointment","min_date":"2023-01-01","max_date":"2024-12-31","default_value":"2023-01-01"}\`
- **File Upload:** \`{"fieldLabel":"Upload your resume","required":true,"help_text":"Supported formats: PDF, DOCX","allowed_file_types":"pdf, docx","max_file_size":5}\`
- **multi_select_checkboxes:** \`{"fieldLabel":"Select your hobbies","required":true,"help_text":"You can select multiple choices","choices":"Reading\\nTraveling\\nCooking\\nSports","default_values_checkboxes":"Reading\\nMusic","min_selections":2,"max_selections":3}\`
- **Signature:** \`{"fieldLabel":"Sign here","required":true,"help_text":"Please sign here to confirm your agreement", "allowUpload":true,"allowTyping":true}\`
For Field Configs values use the appropriate language for the user request.
`;

const AddWorkflow = () => {
  // =================== MAIN WORKFLOW STATE ===================
  const [tasks, setTasks] = useState([]);
  const [workflowName, setWorkflowName] = useState('');
  const [workflowDescription, setWorkflowDescription] = useState('');
  const [workflowDetailedDescription, setWorkflowDetailedDescription] = useState('');

  // Additional fields for Settings 
  const [category, setCategory] = useState('IT');
  const [workflowImage, setWorkflowImage] = useState(null);
  const [visibility, setVisibility] = useState('public');
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [hasDeadline, setHasDeadline] = useState(false);
  const [deadline, setDeadline] = useState(null);
  const [limitUsers, setLimitUsers] = useState(false);
  const [maxUsers, setMaxUsers] = useState(1);
  const [allowMultipleSubmissions, setAllowMultipleSubmissions] = useState(false);
  const [submissionLimit, setSubmissionLimit] = useState(1);


  // ========== GEMINI (AI) CHAT STATE ==========
  const [showGeminiChat, setShowGeminiChat] = useState(false);
  const [showOriginalNavbar, setShowOriginalNavbar] = useState(true);
  const [currentGeminiInstruction, setCurrentGeminiInstruction] = useState(geminiInstruction);
  const [isGeneratingAI, setIsGeneratingAI] = useState(false);

  // Track Which Main Tab Is Active: 'build' | 'settings' | 'dependencies'
  const [currentTab, setCurrentTab] = useState('build');

  // Track Task Dependencies
  const [dependencies, setDependencies] = useState([]);
  const [hasInitializedDeps, setHasInitializedDeps] = useState(false);



  const location = useLocation();

  useEffect(() => {
    // If we navigated here with a "generatedWorkflow" (from PDF),
    // automatically parse it and load it into the builder.
    if (location.state && location.state.generatedWorkflow) {
      handleGeneratedWorkflowFromAI(location.state.generatedWorkflow);
    }
  }, [location]);    

  // --------------------------------------------------
  // CREATE WORKFLOW function
  // --------------------------------------------------
  const createWorkflow = async (e) => {
    e.preventDefault();
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      alert('You are not authenticated. Please login.');
      return;
    }

     // 1) Prepare tasks for the server
    const taskData = tasks.map((task) => ({
      name: task.name,
      description: task.description,
      status: 'Pending',
      task_fields: task.fields.map((field, fieldIndex) => {
        const { fieldLabel, required, ...restConfig } = field.config;
        return {
          fieldTypeID: field.type.toLowerCase(),
          fieldName: fieldLabel || '',
          required: required || true,
          index: fieldIndex,
          config: restConfig,
        };
      }),
    }));

    // 2) Build a map from task UUID to index
    const indexMap = {};
    tasks.forEach((task, idx) => {
      indexMap[task.id] = idx;
    });

    // 3) Transform dependencies from UUID to index-based
    const dependencyData = dependencies.map((dep) => ({
      from_index: indexMap[dep.from_task],
      to_index: indexMap[dep.to_task],
      dependency_type: dep.dependency_type,
    }));

    // Construct the final workflow data
    const workflowData = {
      name: workflowName,
      description: workflowDescription,
      detailed_description: workflowDetailedDescription,
      type: 'primitive',
      category: category,
      status: visibility,
      email_notifications: emailNotifications,
      has_deadline: hasDeadline,
      deadline: deadline,
      limit_users: limitUsers,
      max_users: maxUsers,
      allow_multiple_submissions: allowMultipleSubmissions,
      submission_limit: submissionLimit,

      tasks: taskData,
      dependencies: dependencyData,
    };

    if (workflowImage) {
      workflowData.image_base64 = workflowImage;
    }
    
    console.log('Final Workflow Data:', workflowData);
    try {
      await axios.post(`${config.apiUrl}/workflows/`, workflowData, {
        headers: {
          Authorization: `Token ${authToken}`,
          'Content-Type': 'application/json',
        },
      });
      //toast 
      toast.success('Workflow added successfully');
      // redirect to the workflow list page
      window.location.href = '/admin-dashboard/';

      // Reset
      setWorkflowName('');
      setWorkflowDescription('');
      setWorkflowDetailedDescription('');
      setTasks([]);
      setVisibility('public');
      setWorkflowImage(null);
      setEmailNotifications(false);
      setHasDeadline(false);
      setDeadline(null);
      setLimitUsers(false);
      setMaxUsers(1);
      setAllowMultipleSubmissions(false);
      setSubmissionLimit(1);
    } catch (error) {
      console.error('Failed to add workflow:', error);
      const errorMessage =
        error.response?.data?.message ||
        'An unknown error occurred. Please check your input and try again.';
      Toast.error(errorMessage);
    }
  };

  // --------------------------------------------------
  // TOGGLE GEMINI CHAT
  // --------------------------------------------------
  const toggleGeminiChat = () => {
    setShowGeminiChat(!showGeminiChat);
  };

  // --------------------------------------------------
  // AI-GENERATED WORKFLOW
  // --------------------------------------------------
  const handleGeneratedWorkflowFromAI = (parsedWorkflow) => {
    setIsGeneratingAI(true);

    setTimeout(() => {
      setWorkflowName(parsedWorkflow.name);
      setWorkflowDescription(parsedWorkflow.description);
      setWorkflowDetailedDescription('This is a detailed description of the workflow');

      const clonedTasks = cloneDeep(
        parsedWorkflow.tasks.map((task) => ({
          id: uuidv4(),
          name: task.name,
          description: task.description,
          fields: task.task_fields.map((field) => ({
            id: uuidv4(),
            type: field.fieldTypeID.toLowerCase(),
            config: {
              fieldLabel: field.fieldName || '',
              required: field.required || false,
              ...field.config,
            },
          })),
        }))
      );
      setTasks(clonedTasks);

      // Convert dependency indices to UUIDs
      const newDeps = (parsedWorkflow.dependencies || []).map((dep) => ({
        from_task: clonedTasks[dep.from_task]?.id,
        to_task: clonedTasks[dep.to_task]?.id,
        dependency_type: dep.dependency_type,
      }));
      setDependencies(newDeps);

      // Update instructions for subsequent prompts
      setCurrentGeminiInstruction(
        `${geminiInstruction}\n\nCurrent Workflow:\n${JSON.stringify(parsedWorkflow, null, 2)}\n
         Update fields or add tasks if requested, update "summary" to include the changes Only. 
         Create a new workflow if the request is unrelated to the current workflow.`
      );

      setIsGeneratingAI(false);
    }, 1000);
  };

  // --------------------------------------------------
  // RENDER CONTENT BASED ON CURRENT TAB
  // --------------------------------------------------
  const renderTabContent = () => {
    switch (currentTab) {
      case 'settings':
        // Render the WorkflowSettings component
        return (
          <WorkflowSettings
            // Pass all the states needed for your sub-tabs
            workflowImage={workflowImage}
            setWorkflowImage={setWorkflowImage}
            workflowName={workflowName}
            setWorkflowName={setWorkflowName}
            workflowDescription={workflowDescription}
            setWorkflowDescription={setWorkflowDescription}
            detailedDescription={workflowDetailedDescription}
            setDetailedDescription={setWorkflowDetailedDescription}
            category={category}
            setCategory={setCategory}
            visibility={visibility}
            setVisibility={setVisibility}
            emailNotifications={emailNotifications}
            setEmailNotifications={setEmailNotifications}
            hasDeadline={hasDeadline}
            setHasDeadline={setHasDeadline}
            deadline={deadline}
            setDeadline={setDeadline}
            limitUsers={limitUsers}
            setLimitUsers={setLimitUsers}
            maxUsers={maxUsers}
            setMaxUsers={setMaxUsers}
            allowMultipleSubmissions={allowMultipleSubmissions}
            setAllowMultipleSubmissions={setAllowMultipleSubmissions}
            submissionLimit={submissionLimit}
            setSubmissionLimit={setSubmissionLimit}
            showOriginalNavbar={showOriginalNavbar}
          />
        );

        case 'dependencies':
          // Only do the “default dependencies” logic once
          if (!hasInitializedDeps && tasks.length > 1 && dependencies.length === 0) {
            const newDeps = [];
            for (let i = 0; i < tasks.length - 1; i++) {
              newDeps.push({
                from_task: tasks[i].id,
                to_task: tasks[i + 1].id,
                dependency_type: 'no_restriction',
              });
            }
            setDependencies(newDeps);
            setHasInitializedDeps(true);
          }
  
          return (
            <div>
              <h2 className={styles.dependencyPageTitle}>Task Dependencies</h2>
              <p className={styles.dependencyPageDescription}>
                Define how tasks connect and set what users can do at each step. 
                You can manage dependencies visually in the diagram or directly in the table below.
              </p>
              {/* IMPORTANT: Wrap AdminDependenciesView in ReactFlowProvider */}
              <ReactFlowProvider>
                <AdminDependenciesView
                  tasks={tasks}                   // pass tasks from parent
                  dependencies={dependencies}     // pass dependencies
                  setDependencies={setDependencies}
                />
              </ReactFlowProvider>
            </div>
          );

      case 'build':
      default:
        // Render the WorkflowBuilder
        return (
          <WorkflowBuilder
            tasks={tasks}
            setTasks={setTasks}
            workflowName={workflowName}
            setWorkflowName={setWorkflowName}
            workflowDescription={workflowDescription}
            setWorkflowDescription={setWorkflowDescription}
            workflowDetailedDescription={workflowDetailedDescription}
            setWorkflowDetailedDescription={setWorkflowDetailedDescription}
            showOriginalNavbar={showOriginalNavbar}
          />
        );
    }
  };

  // --------------------------------------------------
  // MAIN RETURN
  // --------------------------------------------------
  return (
    <div>
      <NavbarAddWorkflow
        setShowOriginalNavbar={setShowOriginalNavbar}
        showOriginalNavbar={showOriginalNavbar}
        onPublish={createWorkflow}
        currentTab={currentTab}
        onTabChange={(tabName) => setCurrentTab(tabName)}
      />

      {/* Spacer to push the content below the top navbar */}
      <div className={styles.spacer} />

      {/* Conditionally Render the Selected Tab */}
      {renderTabContent()}

      {/* Gemini Floating Button */}
      <div className={styles.geminiButton} onClick={toggleGeminiChat}>
        <FontAwesomeIcon icon={faRobot} />
      </div>

      {/* Gemini Chat Window */}
      <AIAssistant
        showChat={showGeminiChat}
        toggleChat={toggleGeminiChat}
        onGenerateContent={handleGeneratedWorkflowFromAI}
        instruction={currentGeminiInstruction}
      />

      {/* Add the AI generation overlay */}
      {isGeneratingAI && (
        <div className={styles.aiOverlay}>
          <div className={styles.spinner}></div>
          <p>Generating from AI...</p>
        </div>
      )}
    </div>
  );
};

export default AddWorkflow;
