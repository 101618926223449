import React, { useState, useMemo, useEffect } from 'react';

import { Draggable, Droppable } from 'react-beautiful-dnd';
import styles from '../../styles/components/WorkflowBuilder/Toolbar.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {   faSearch, faChevronLeft, faChevronRight, faFont, faHashtag, faCaretDown, faCheckSquare, faDotCircle, faList, faCalendar, faFile, faSignature, faTextHeight } from '@fortawesome/free-solid-svg-icons';



// Example tab data for demonstration
export const toolbarTabs = {
  Basic: [
    { id: 'text', type: 'text', label: 'Text', icon: faFont },
    { id: 'number', type: 'number', label: 'Number', icon: faHashtag },
    { id: 'dropdown', type: 'spinner', label: 'Dropdown', icon: faCaretDown },
    { id: 'checkbox', type: 'checkbox', label: 'Checkbox', icon: faCheckSquare },
    { id: 'radio', type: 'radiobutton', label: 'Radio', icon: faDotCircle },
    { id: 'multi_select', type: 'multi_select_checkboxes', label: 'Multi Select', icon: faList },
    { id: 'date', type: 'date', label: 'Date', icon: faCalendar },
    { id: 'file', type: 'file_upload', label: 'File Upload', icon: faFile },
    { id: 'textarea', type: 'textarea', label: 'Textarea', icon: faTextHeight },
  ],
  Widget: [
    { id: 'signature', type: 'signature', label: 'Signature', icon: faSignature },
  ],
  Templates: [
    // Future templates can be added here
  ],
};

function Toolbar({ showOriginalNavbar }) {
  const [activeTab, setActiveTab] = useState('Basic');
  const [isOpen, setIsOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  // Calculate vertical offset:
  // 120px if the first navbar is visible, else 60px
  const toolbarTop = useMemo(() => (showOriginalNavbar ? 120 : 60), [showOriginalNavbar]);

  // Toggle open/closed states for the drawer
  const toggleToolbar = () => {
    setIsOpen((prev) => !prev);
  };

  // Filter the fields by search term
  const filteredTabs = Object.fromEntries(
    Object.entries(toolbarTabs).map(([tabName, items]) => {
      const filteredItems = items.filter((item) =>
        item.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
      return [tabName, filteredItems];
    })
  );

  return (
    <>
      {/* MAIN TOOLBAR CONTAINER */}
      <div
        className={`${styles.toolbarContainer} ${isOpen ? styles.open : styles.closed}`}
        style={{ top: `${toolbarTop}px` }}
      >
        {/* SEARCH BAR */}
        <div className={styles.searchWrapper}>
          <div className={styles.searchBox}>
            <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
            <input
              type="text"
              placeholder="Search fields..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        {/* TABS */}
        <div className={styles.tabsRow}>
          {Object.keys(toolbarTabs).map((tabName) => (
            <button
              key={tabName}
              className={`${styles.tabButton} ${
                activeTab === tabName ? styles.activeTab : ''
              }`}
              onClick={() => setActiveTab(tabName)}
            >
              {tabName}
              {/* Animated underline if active */}
              {activeTab === tabName && <span className={styles.activeUnderline} />}
            </button>
          ))}
        </div>

        {/* DRAGGABLE FIELDS */}
        <div className={styles.fieldsContainer}>
          <Droppable droppableId="toolbar" type="field" isDropDisabled={true}>
            {(provided) => (
              <div
                className={styles.fieldsList}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {filteredTabs[activeTab].length > 0 ? (
                  filteredTabs[activeTab].map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={`toolbar-${item.id}`}
                      index={index}
                    >
                      {(providedInner) => (
                        <div
                          className={styles.fieldItem}
                          ref={providedInner.innerRef}
                          {...providedInner.draggableProps}
                          {...providedInner.dragHandleProps}
                        >
                          <FontAwesomeIcon icon={item.icon} className={styles.fieldIcon} />
                          <span>{item.label}</span>
                        </div>
                      )}
                    </Draggable>
                  ))
                ) : (
                  <p className={styles.noResults}>No matching items...</p>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>

      {/* TOGGLE BUTTON (FLOATING) */}
      <button
        className={`${styles.toggleButton} ${isOpen ? styles.openBtn : styles.closedBtn}`}
        onClick={toggleToolbar}
        style={{ top: `${toolbarTop + 20}px` }}
      >
        <FontAwesomeIcon icon={isOpen ? faChevronLeft : faChevronRight} />
      </button>
    </>
  );
}

export default Toolbar;