import React, { useState, useEffect } from 'react';
import { calculateLabelWidth } from './FieldTypesUtils';
import styles from '../../styles/components/FieldTypes/FieldTypes.module.css';

export const TextAreaFieldConfig = [
  "fieldLabel",
  "required",
  "help_text",
  "placeholder",
  "max_length",
  "rows",
  "default_value"
];

function TextAreaField({ field, onConfigChange, isSelected }) {
  const [label, setLabel] = useState(field.config.fieldLabel);
  const [helpText, setHelpText] = useState(field.config.help_text || '');
  const isRequired = field.config.required;

  const handleLabelChange = (e) => {
    const updatedConfig = { ...field.config, fieldLabel: e.target.value };
    onConfigChange(updatedConfig);
  };

  const handleHelpTextChange = (e) => {
    const updatedConfig = { ...field.config, help_text: e.target.value };
    setHelpText(e.target.value);
    onConfigChange(updatedConfig);
  };

  useEffect(() => {
    setLabel(field.config.fieldLabel);
    setHelpText(field.config.help_text || '');
    const labelWidth = calculateLabelWidth(field.config.fieldLabel);
    document.documentElement.style.setProperty('--label-length-'+field.id, labelWidth/8);
  }, [field.config]);

  return (
    <div className={styles.textField}>
      <div className={styles.labelWrapper}>
        <div className={styles.labelContainer}>
          <textarea
            className={`${styles.fieldLabel} ${isRequired ? styles.required : ''}`}
            value={label}
            placeholder={isSelected ? "Type a label" : ""}
            onChange={handleLabelChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.currentTarget.blur();
              }
            }}
            onInput={(e) => {
              e.target.style.height = 'auto';
              e.target.style.height = `${e.target.scrollHeight}px`;
            }}
            rows="1"
            wrap="soft"
          />
          {isRequired && <span 
            style={{ 
              left: 'max(min(calc(10px + 1ch *var(--label-length-'+field.id+')), calc(100% - 40px)),calc(14%))',
              top: '5px',
              position: 'absolute',
              color: 'red'
            }}
          >*</span>}
        </div>
      </div>

      <textarea
        className={styles.textareaInput}
        placeholder={field.config.placeholder}
        rows={field.config.rows || 5}
        maxLength={field.config.max_length}
        defaultValue={field.config.default_value}
        disabled
      />

      <textarea
        className={styles.helpText}
        value={helpText}
        placeholder={isSelected ? "Type a help text" : ""}
        onChange={handleHelpTextChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.currentTarget.blur();
          }
        }}
        onInput={(e) => {
          e.target.style.height = 'auto';
          e.target.style.height = `${e.target.scrollHeight}px`;
        }}
        rows="1"
        wrap="soft"
      />
    </div>
  );
}

export default TextAreaField;
