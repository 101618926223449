import React, { useState } from 'react';
import { FaCodeBranch, FaUser, FaCheck } from 'react-icons/fa';
import { FileText } from 'lucide-react';
import styles from '../../styles/components/Modals/PublishWorkflowModal.module.css';

function PublishWorkflowModal({ show, onClose, onPublish }) {
  const [publishType, setPublishType] = useState('newVersion'); // Default to newVersion
  const [notifyUsers, setNotifyUsers] = useState(false);

  if (!show) return null;

  const handlePublish = () => {
    console.log('Modal sending publishType:', publishType);
    onPublish({
      publishType, // Send the raw value
      notifyUsers
    });
    onClose();
  };

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const isNewVersion = publishType === 'newVersion';
  const isUpdateCurrent = publishType === 'updateCurrent';

  return (
    <div className={styles.backdrop} onClick={handleBackdropClick}>
      <div className={styles.modalContainer}>

        {/* Purple header */}
        <div className={styles.modalHeader}>
          <h2 className={styles.modalTitle}>Publish Workflow</h2>
          <button className={styles.closeButton} onClick={onClose}>
            &times;
          </button>
        </div>

        {/* Body content */}
        <div className={styles.modalBody}>
          <div className={styles.optionsWrapper}>
            {/* CREATE NEW VERSION card */}
            <label
              className={`${styles.optionCard} ${
                isNewVersion ? styles.optionSelected : ''
              }`}
            >
              <input
                type="radio"
                name="publishType"
                value="newVersion"  // This must match exactly
                className={styles.radioInput}
                checked={isNewVersion}
                onChange={(e) => {
                  console.log('Radio changed to:', e.target.value);
                  setPublishType(e.target.value);
                }}
              />
              {isNewVersion && (
                <div className={styles.checkMark}>
                  <FaCheck />
                </div>
              )}
              {/* Circle for the icon */}
              <div
                className={`${styles.iconCircle} ${
                  isNewVersion ? styles.iconCircleSelected : ''
                }`}
              >
                <FaCodeBranch
                  className={`${styles.optionCardIcon} ${
                    isNewVersion ? styles.iconSelected : ''
                  }`}
                />
              </div>
              {/* Title and description */}
              <div className={styles.optionCardTexts}>
                <h3 className={styles.optionCardTitle}>Create New Version</h3>
                <p className={styles.optionCardDesc}>
                  Creates a separate version while preserving the original workflow
                </p>
              </div>
            </label>

            {/* UPDATE CURRENT VERSION card */}
            <label
              className={`${styles.optionCard} ${
                isUpdateCurrent ? styles.optionSelected : ''
              }`}
            >
              <input
                type="radio"
                name="publishType"
                value="updateCurrent"  // This must match exactly
                className={styles.radioInput}
                checked={isUpdateCurrent}
                onChange={(e) => {
                  console.log('Radio changed to:', e.target.value);
                  setPublishType(e.target.value);
                }}
              />
              {isUpdateCurrent && (
                <div className={styles.checkMark}>
                  <FaCheck />
                </div>
              )}
              <div
                className={`${styles.iconCircle} ${
                  isUpdateCurrent ? styles.iconCircleSelected : ''
                }`}
              >
                <FileText
                  className={`${styles.optionCardIcon} ${
                    isUpdateCurrent ? styles.iconSelected : ''
                  }`}
                />
              </div>
              <div className={styles.optionCardTexts}>
                <h3 className={styles.optionCardTitle}>Update Current Version</h3>
                <p className={styles.optionCardDesc}>
                  Overwrites the existing workflow with your changes
                </p>
              </div>
            </label>
          </div>

          {/* Notify row */}
          <div className={styles.notifyCard}>
            <div className={styles.notifyLeft}>
              <FaUser className={styles.notifyIcon} />
              <p className={styles.notifyText}>Notify users about changes</p>
            </div>
            <div
              className={`${styles.customToggle} ${notifyUsers ? styles.on : ''}`}
              onClick={() => setNotifyUsers(!notifyUsers)}
            >
              <div className={styles.toggleHandle} />
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className={styles.modalFooter}>
          <button className={styles.cancelButton} onClick={onClose}>
            Cancel
          </button>
          <button className={styles.publishButton} onClick={handlePublish}>
            Publish
          </button>
        </div>
      </div>
    </div>
  );
}

export default PublishWorkflowModal;
